import { Container, Grid, Typography, Box,Tab, Tabs, AppBar } from '@material-ui/core';
import React, { useEffect } from 'react';
import Aos from 'aos'
import "aos/dist/aos.css"
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

import { Helmet } from 'react-helmet';

// import ESGGreenInvest from "../../asset/images/esg/esg_greeninvest/greeninvest_banner.webp"

import ProductCTAA from '../../Component/ProductCTAA';
import whyCP from "../../asset/images/product/creditpulse/why_credit_pulse.webp"

import solution1 from "../../asset/images/esg/esg_greeninvest/solutions/solution1.webp";
import solution2 from "../../asset/images/esg/esg_greeninvest/solutions/solution2.webp";
import solution3 from "../../asset/images/esg/esg_greeninvest/solutions/solution3.webp";

import greeninvst_pulse from "../../asset/images/esg/esg_greeninvest/greeninvst_pulse.webp";
import { Link } from 'react-router-dom';

export default function ESGGreenInvestPulse() {

    useEffect(() => {
        Aos.init({ duration: 1000 });
    }, []);


        // main tabs
        const [valueESG, setValueESG] = React.useState(3);

        const handleChangeESG = (event, newValue) => {
            window.scrollTo(0, 0);
            setValueESG(newValue);
        };
    
        const [value, setValue] = React.useState(2);
    
        const handleChange = (event, newValue) => {
            setValue(newValue);
        };

    return (
        <>

            <Helmet>
                <meta charset="UTF-8" />
                <title> ESG GreenInvest Pulse Solutions </title>
                <meta name="description" content="Transform ESG management with our expert solutions." />
                <meta name="keywords" content="ESG management, Sustainability reporting, Environmental metrics, Social responsibility, Governance compliance, Net zero alignment, Carbon exposure, SFDR reporting, SDR compliance, Data integration, Cloud implementation, Tailored ESG solutions, Sustainable finance, Responsible investment, Asset management strategies" />
                <meta name="author" content="Decimal Point Analytics" />
                <link rel="canonical" href="https://www.decimalpointanalytics.com/align-your-investments-with-global-sustainability-goals" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            </Helmet>


            {/* <Box className="banner_bx">
                <Box style={{ backgroundImage: "url(" + ESGGreenInvest + ")" }} className="banner_img">
                    <Box className="img_over bl_overlay">
                        <Grid container spacing={0} alignItems="center" justifyContent='center'>
                            <Grid item lg={5} md={7} sm={10} xs={10}>
                                <Box className="al_center">
                                    <Typography className="white" variant="h1">ESG GreenInvest Pulse</Typography>
                                    <Box mt={'10px'}>
                                        <Typography className="white" variant="h4">Align Your Investments with Global Sustainability Goals
                                        </Typography>
                                    </Box>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </Box> */}

<Box id="esg" className="stiky3">
                <Box className="maintabbedsection linkedtabs">
                    <Container maxWidth="lg" className="bdr">
                        <Grid container spacing={1} alignItems="top" justifyContent="center">
                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                <AppBar position="static" className="tabbedbar dgrid_center">
                                    <Tabs value={valueESG} onChange={handleChangeESG} variant="scrollable">
                                        <Tab
                                            label="ESG"
                                            component={Link}
                                            to="/environmental-social-and-governance-for-financial-institutions-and-enterprise-businesses"
                                            value={0}
                                        />
                                        <Tab
                                            label="ESG Insights"
                                            component={Link}
                                            to="/esg-insights/esg-whitepaper"
                                            value={1}
                                        />
                                        <Tab
                                            label="ESG Roundtable"
                                            component={Link}
                                            to="/environmental-social-and-governance-for-financial-institutions-and-enterprise-businesses#esg-executable-roundtable"
                                            value={2}
                                        />
                                        <Tab
                                            label="ESG Products"
                                            component={Link}
                                            to="/empowering-esg-investment-with-real-time-analytics"
                                            value={3}
                                        />
                                    </Tabs>
                                </AppBar>
                            </Grid>
                        </Grid>
                    </Container>
                </Box>
            </Box>


            <Box className="maintabbedsection stiky4 linkedtabs">
                <Container maxWidth="lg" className="bdr">
                    <Grid container spacing={1} alignItems="top" >
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                            <AppBar position="static" className="tabbedbar dgrid_center">
                                <Tabs value={value} onChange={handleChange} variant="scrollable">
                                    <Tab
                                        label="News Senti-Meter"
                                        component={Link}
                                        to="/empowering-esg-investment-with-real-time-analytics"
                                        value={0}
                                    />
                                    <Tab
                                        label="Private Equity"
                                        component={Link}
                                        to="/elevating-private-equity-performance-with-strategic-esg-solutions"
                                        value={1}
                                    />
                                    <Tab
                                        label="Greenlnvest Pulse"
                                        component={Link}
                                        to="/align-your-investments-with-global-sustainability-goals"
                                        value={2}
                                    />
                                </Tabs>
                            </AppBar>
                        </Grid>
                    </Grid>
                </Container>
            </Box>

            <Box id='esg_news' className='section2 sec_bg' component='section'mt={4}>
                <Container maxWidth='lg' className='bdr'>
                    <Grid container direction="row"
                        justifyContent="center"
                        alignItems="center">
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                            <Box className="al_center synopsic_bx" >
                                <Typography variant='h6' className='black jst' gutterBottom>
                                    Empower your asset management strategies with Decimal Point Analytics' ESG GreenInvest Pulse, the premier suite of ESG solutions crafted to advance your environmental, social, and governance reporting and compliance. Designed for asset managers focused on public markets – equity or fixed income – this platform seamlessly integrates with your internal system, enabling you to track sustainability metrics of your portfolio. With ESG GreenInvest Pulse, you’re not just managing assets; you’re leading the way in responsible investment and fostering a sustainable future.
                                </Typography>
                            </Box>
                        </Grid>

                        <Grid item lg={12} md={12} sm={12} xs={12}>
                            <Box className='flx_center' mt={2}>
                                <ProductCTAA allignment="flx_fs" cta_btn_name="Schedule a demo" pageVisited="ESG GreenInvest Pulse" />
                            </Box>
                        </Grid>
                    </Grid>
                </Container>
            </Box>

            <Box id='esg_news' className='section mob_card' component='section'>
                <Container maxWidth='lg' className='bdr'>
                    <Grid container spacing={2} alignItems="center" justifyContent='center'>
                        <Grid container spacing={1} alignItems="top">
                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                <Box className='al_center'>
                                    <Typography variant='h2' className='black'>
                                        Drive Reporting <span className='lower'>and</span> Assurance <span className='lower'>using</span> DPA’s GreenInvest Pulse
                                    </Typography>
                                </Box>
                            </Grid>
                        </Grid>

                        <Grid container direction="row"
                            justifyContent="center"
                            alignItems="center">
                            <Grid item lg={10} md={11} sm={12} xs={12}>
                                <Box className="al_center" mt={3}>
                                    <img src={greeninvst_pulse} className="topbanner2" alt="Banner2" />
                                </Box>
                            </Grid>
                        </Grid>

                        <Grid item lg={12} md={12} sm={12} xs={12}>
                            <Box mt={2} className='flx_center'>
                                <ProductCTAA allignment="flx_fs" cta_content="Enhance Your Strategy" cta_btn_name="Book a Demo " pageVisited="ESG GreenInvest Pulse" />
                            </Box>
                        </Grid>
                    </Grid>

                </Container>
            </Box>

            <Box id='esg_news' className='section mob_card sec_bg' component='section'>
                <Container maxWidth='lg' className='bdr' style={{ position: 'relative', }}>

                    <Grid container spacing={1} alignItems="top">
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                            <Box className='al_center'>
                                <Typography variant='h2' className='black'>
                                    Customized ESG Solutions <span className='lower'>for</span> Precision <span className='lower'>and</span> Flexibility
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>

                    <Grid container spacing={3} alignItems="center" justifyContent='center'>

                        <Grid item lg={4} md={4} sm={6} xs={12}>
                            <Box className='boxbg mnhg2'>
                                <Box className='al_center'>
                                    <img src={solution1} className='allic' alt='ES1' />
                                </Box>
                                <Box>
                                    <Grid container spacing={1} alignItems="top" justifyContent='center'>
                                        <Grid item lg={8} md={12} sm={12} xs={12}>
                                            <Typography variant='h6' className='blue al_center fw6'>
                                                Tailored Offerings
                                            </Typography>
                                        </Grid>
                                    </Grid>

                                    <Typography variant='h6' className='black al_center mb0'>
                                        Adapt our solutions to meet your specific needs, from data integration to complete system setups.
                                    </Typography>
                                </Box>
                            </Box>
                        </Grid>

                        <Grid item lg={4} md={4} sm={6} xs={12}>
                            <Box className='boxbg mnhg2'>
                                <Box className='al_center'>
                                    <img src={solution2} className='allic' alt='ES2' />
                                </Box>
                                <Box>
                                    <Grid container spacing={1} alignItems="top" justifyContent='center'>
                                        <Grid item lg={8} md={12} sm={12} xs={12}>
                                            <Typography variant='h6' className='blue al_center fw6'>
                                                Flexible Implementation
                                            </Typography>
                                        </Grid>
                                    </Grid>

                                    <Typography variant='h6' className='black al_center mb0'>
                                        Opt between implementation on our cloud or your infrastructure.
                                    </Typography>
                                </Box>
                            </Box>
                        </Grid>

                        <Grid item lg={4} md={4} sm={6} xs={12}>
                            <Box className='boxbg mnhg2'>
                                <Box className='al_center'>
                                    <img src={solution3} className='allic' alt='ES3' />
                                </Box>
                                <Box>
                                    <Grid container spacing={1} alignItems="top" justifyContent='center'>
                                        <Grid item lg={8} md={12} sm={12} xs={12}>
                                            <Typography variant='h6' className='blue al_center fw6'>
                                                Trusted Data Sources
                                            </Typography>
                                        </Grid>
                                    </Grid>

                                    <Typography variant='h6' className='black al_center mb0'>
                                        Rely on data from authoritative sources like company filings, SBTi, TPI, and NetZero Tracker for impeccable compliance and accuracy.
                                    </Typography>
                                </Box>
                            </Box>
                        </Grid>

                    </Grid>
                </Container>
            </Box>

            <Box id='esg_news' className="section" component="section">
                <Container maxWidth="lg" className="bdr">
                    <Grid container spacing={0} alignItems="top" justifyContent='center'>
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                            <Box className="al_center">
                                <Typography className="black" variant="h2">
                                    Why Partner <span className='lower'>with</span> Decimal Point Analytics?
                                </Typography>
                            </Box>
                        </Grid>

                        <Grid item lg={5} md={5} sm={6} xs={12}>
                            <Box className='al_center'>
                                <img src={whyCP} className='topbanner5' alt='Why Partner with Decimal Point Analytics? ' />
                            </Box>
                        </Grid>

                        <Grid item lg={7} md={7} sm={6} xs={12}>
                            <Box>
                                <Typography className="blue" variant="h6">
                                    <ul>
                                        <li>
                                            <Typography variant="h6" className="black jst" gutterbottom>
                                                <strong>Proven Expertise</strong>
                                            </Typography>
                                        </li>

                                        <li>
                                            <Typography variant="h6" className="black jst" gutterbottom>
                                                <strong>Customized ESG Strategies</strong>
                                            </Typography>
                                        </li>

                                        <li>
                                            <Typography variant="h6" className="black jst" gutterbottom>
                                                <strong>Global Compliance and Reporting</strong>
                                            </Typography>
                                        </li>

                                        <li>
                                            <Typography variant="h6" className="black jst" gutterbottom>
                                                <strong>Robust Data Integrity</strong>
                                            </Typography>
                                        </li>

                                        <li>
                                            <Typography variant="h6" className="black jst" gutterbottom>
                                                <strong> Innovative Technology</strong>
                                            </Typography>
                                        </li>

                                        <li>
                                            <Typography variant="h6" className="black jst" gutterbottom>
                                                <strong> Dedicated Support</strong>
                                            </Typography>
                                        </li>

                                    </ul>
                                </Typography>
                            </Box>
                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                <Box className='flx_fs mob_center'>
                                    <ProductCTAA allignment="flx_fs" cta_content="Learn more About Our Expertise" cta_btn_name="Contact Us" pageVisited="ESG GreenInvest Pulse" />
                                </Box>
                            </Grid>

                        </Grid>


                    </Grid>
                </Container>
            </Box>

            <Box id='esg_news' className="sec_bg py3" component="section">
                <Container maxWidth="lg" className="bdr">
                    <Grid container spacing={0} alignItems="center" justifyContent='flex-start'>
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                            <Box className="synopsic_bx">
                                <Box className='al_center'>
                                    <Typography variant='h2' className='black'>
                                        Achieve ESG Excellence <span className='lower'>with</span> Our Tailored Solutions
                                    </Typography>
                                </Box>
                                <Typography variant='h6' className='black'>
                                    Join us at Decimal Point Analytics, where your commitment to sustainability meets our expertise in ESG integration. With our ESG GreenInvest Pulse suite, you gain more than tools—you gain a strategic partner dedicated to enhancing your portfolio's performance and sustainability footprint. Ready to transform your investment approach?
                                </Typography>
                            </Box>
                        </Grid>

                        <Grid item lg={12} md={12} sm={12} xs={12}>
                            <Box mt={2} className='flx_center'>
                                <ProductCTAA allignment="al_center" cta_content="Explore how we can help you lead with innovation and integrity in the ESG landscape" cta_btn_name="Schedule your demo today" pageVisited="ESG GreenInvest Pulse" />
                            </Box>
                        </Grid>
                    </Grid>
                </Container>
            </Box>


        </>
    );
}
