import { Container, Grid, Typography, Box, Button } from "@material-ui/core";
import React, { useEffect, } from "react";
import Aos from "aos";
import "aos/dist/aos.css";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { Link } from "react-router-dom";

import ProductCTAA from '../../Component/ProductCTAA'


import stories from "../../asset/images/landing_page/stories.webp"
import blogs from "../../asset/images/landing_page/blogs.webp"
import podcast from "../../asset/images/landing_page/podcast.webp"

import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';

export default function HomeResources() {
  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);



  return (
    <>
      <Box className="section sec_bg resource" component="section">
        <Container maxWidth="lg" className="bdr">
          <Grid container spacing={1} alignItems="top">
            <Grid item lg={12} md={12} sm={12} xs={12} >
              <Box className="al_center">
                <Typography className="black" variant="h2">
                  Explore Our Resources
                </Typography>
              </Box>
            </Grid>
          </Grid>

          <Box mt={3}>
            <Grid container spacing={0} alignItems="top">
              <Grid item lg={4} md={4} sm={4} xs={6} >
                <Box className="reso_bx">
                  <Box style={{ backgroundImage: "url(" + stories + ")" }} className="reso_img">
                    <Box className="img_over bl_overlay">
                      <Box className="al_center">
                        <Typography className="white fw5" variant="h5">Success Stories</Typography>
                        {/* <Typography className="white flx_center" variant="h6">Read More <ArrowRightAltIcon className="r_arrow" /></Typography> */}
                        <Box pt={1}>
                          <Link to="/case-studies">
                            <Button disableRipple variant="text" className="read_more white" endIcon={<ArrowRightAltIcon />}>Read More </Button>
                          </Link>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Grid>

              <Grid item lg={4} md={4} sm={4} xs={6} >
                <Box className="reso_bx">
                  <Box style={{ backgroundImage: "url(" + blogs + ")" }} className="reso_img">
                    <Box className="img_over skbl_overlay">
                      <Box className="al_center">
                        <Typography className="white fw5" variant="h5">Blogs</Typography>
                        {/* <Typography className="white flx_center" variant="h6">Read More <ArrowRightAltIcon className="r_arrow" /></Typography> */}
                        <Box pt={1}>
                          <Link to="/blog">
                            <Button disableRipple variant="text" className="read_more white" endIcon={<ArrowRightAltIcon />}>Read More </Button>
                          </Link>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </Box>

              </Grid>

              <Grid item lg={4} md={4} sm={4} xs={12} >
                <Box className="reso_bx">
                  <Box style={{ backgroundImage: "url(" + podcast + ")" }} className="reso_img">
                    <Box className="img_over bl_overlay gry_overlay">
                      <Box className="al_center">
                        <Typography className="white fw5" variant="h5">Podcast</Typography>
                        <Box pt={1}>
                          <Link to="/podcast">
                            <Button disableRipple variant="text" className="read_more white" endIcon={<ArrowRightAltIcon />}>Read More </Button>
                          </Link>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </Box>

              </Grid>
            </Grid>
          </Box>

          <Box mt={5}>
            <ProductCTAA allignment="al_center" cta_btn_name="Schedule a free Demo today!" pageVisited="Artificial Intelligence" />
          </Box>

        </Container>
      </Box>
    </>
  );
}
