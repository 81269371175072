import { Container, Grid, Typography, Box, } from '@material-ui/core';
import React, { useEffect } from 'react';
import Aos from 'aos'
import "aos/dist/aos.css"
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import Writemessage3 from '../../Component/Writemessage3.js'
import qc from "../../asset/images/animation/quantumcomputingmid.svg";

import Quantum from "../../asset/images/new_banner/quantum_computing_banner.webp"
import { Helmet } from 'react-helmet';

export default function Quantum_computing() {



    useEffect(() => {
        Aos.init({ duration: 1000 });
    }, []);



    return (
        <>

            <Helmet>
                <title>Quantum Computing for Asset Allocation | Decimal Point Analytics </title>
                <meta name="description" content="Explore the future of finance with quantum computing solutions. Discover how our innovative approaches empower businesses to tackle complex data challenges" />
                <link rel="canonical" href="https://www.decimalpointanalytics.com/quantum-computing" />
            </Helmet>

            {/* <Box className='mainsection' component='section'>
                <Container maxWidth='lg'>
                    <Grid container spacing={1} alignItems="center">
                        <Grid item lg={5} md={6} sm={6} xs={12}>
                            <Box>
                                <Typography variant='h1' className='blue'>
                                    Quantum Computing for Asset Allocation
                                </Typography>

                                <Typography variant='h4' className='black'>
                                    World's first implementation of asset allocation using quantum computing
                                </Typography>

                            </Box>
                        </Grid>
                        <Grid item lg={7} md={6} sm={6} xs={12}>
                            <Box className='al_right'>
                                <img src={indexbanner} className='topbanner' alt='Index Banner' />
                            </Box>
                        </Grid>
                    </Grid>
                </Container>
            </Box> */}

            <Box className="banner_bx">
                <Box style={{ backgroundImage: "url(" + Quantum + ")" }} className="banner_img">
                    <Box className="img_over bl_overlay">
                        <Grid container spacing={0} alignItems="center" justifyContent='center'>
                            <Grid item lg={4} md={5} sm={5} xs={9}>
                                <Box className="al_center" mt={3}>
                                    <Typography className="white" variant="h1">Quantum Computing For Asset Allocation</Typography>
                                    <Box mt={'10px'}>
                                        <Typography className="white" variant="h4">World's First Implementation Of Asset Allocation Using Quantum Computing</Typography>
                                    </Box>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </Box>

            <Box className='section2 sec_bg' component='section'>
                <Container maxWidth='lg' className='bdr'>

                    {/* <Grid container spacing={1} alignItems="top">
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                            <Box className='al_center'>
                                <Typography variant='h2' className='black'>
                                    Quantum Computing
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid> */}


                    <Grid container direction="row"
                        justifyContent="center"
                        alignItems="center">
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                            <Box className="al_center synopsic_bx">
                                <Typography variant='h6' className='black jst'>
                                    Quantum Computing is an emerging technology that harnesses the laws of quantum mechanics for solving complex problems which are either difficult or inefficient to solve using classical computers. As a result, quantum computers will be far more efficient than classical computers in many areas, bringing a revolution to real-world computing. A quantum processor has millions of qubits that explore all possible combinations to determine the best path to significantly transform AI and ML. A qubit (or quantum bit) is the basic unit of quantum information (the quantum equivalent of the classical binary bit). Quantum computing, combined with current growth in advanced technologies, will dramatically change science and business for the better.
                                </Typography>

                                <Typography variant='h6' className='black jst'>
                                    Quantum computing changes the way information is stored and processed. It greatly improves the efficiency of algorithms, particularly optimization problems. While research on quantum computing is progressing fast, there is still some time before it will be mainstream. However, as the disruption will change the modus operandi of several industries, early movers will benefit a lot. The Global Quantum Computing market is expected to grow rapidly and reach $65 billion by 2030 from approximately $2 billion in 2022. Decimal Point Analytics, whose DNA is innovation, started investing in quantum computing in early 2020 to be future-ready and gain maximum from the quantum revolution.
                                </Typography>


                            </Box>

                        </Grid>
                    </Grid>
                </Container>
            </Box>

            <Writemessage3 pageVisited="Quantum Computing for Asset Allocation" />

            <Box className='section' component='section'>
                <Container maxWidth='lg' className='bdr'>
                    <Grid container spacing={1} alignItems="center">

                        <Grid item lg={5} md={5} sm={5} xs={12}>
                            <Box className='al_left'>
                                <img src={qc} className='topbanner' alt='Quantum Computing' />
                            </Box>
                        </Grid>

                        <Grid item lg={7} md={7} sm={7} xs={12}>
                            <Box mt={4}>
                                <Typography variant='h4' className='blue'>Quantum Computing in financial services will bring many changes. Some of them are:</Typography>
                                <Typography variant='h6' className='black al_left'>
                                    <ul>
                                        <li>Significant improvement in transaction speed and thus reducing processing cost</li>
                                        <li>Predictive Analytics (Consumer Behaviour, Market trend etc.) by a combination of quantum computing and AI</li>
                                        <li>Optimizing portfolio management for asset/asset classes with dependencies</li>
                                        <li>Recognition of fraudulent activities by pattern recognition thus leads to better fraud risk management</li>
                                        <li>Quantum computers are particularly promising where algorithms are powered by live data streams, such as real-time equity prices, which carry a high level of random noise.</li>
                                    </ul>
                                </Typography>
                            </Box>

                            <Box mt={4}>
                                <Typography variant='h4' className='blue'>DPA has completed two projects in Quantum Computing which are:</Typography>
                                <Typography variant='h6' className='black al_left'>
                                    <ul>
                                        <li>Portfolio optimization (Markowitz Optimization)</li>
                                        <li>Algorithm testing for Robo-Pulse (DPA’s own Robo-Advisory product using the Black Litterman model)</li>

                                    </ul>
                                </Typography>
                            </Box>

                            <Box mt={4}>
                                <Typography variant='h6' className='black jst'>
                                    Both these projects are done using D-Wave systems. D-Wave systems use a process called quantum annealing to search for solutions to a problem which is effective for optimization problems. The gate-based system is the other type of Quantum Computing system.
                                </Typography>

                                <Typography variant='h6' className='black jst'>
                                    In the coming years, DPA will focus on portfolio management, fraud detection, and risk analytics. In addition, DPA aspires to undertake its first commercial Quantum Computing based project in 2023-24.
                                </Typography>
                            </Box>

                        </Grid>

                    </Grid>
                </Container>
            </Box>


        </>
    );
}
