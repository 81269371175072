import { Container, Grid, Typography, Box, Button, IconButton } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import Aos from 'aos'
import "aos/dist/aos.css"
import Writemessage3 from '../../Component/Writemessage3'
import { Helmet } from 'react-helmet';
import adv1 from "../../asset/images/product/ecopulse/adv1.svg";
import adv2 from "../../asset/images/product/ecopulse/adv2.svg";
import adv3 from "../../asset/images/product/ecopulse/adv3.svg";
import adv4 from "../../asset/images/product/ecopulse/adv4.svg";
import adv5 from "../../asset/images/product/ecopulse/adv5.svg";
import adv6 from "../../asset/images/product/ecopulse/adv6.svg";
import adv7 from "../../asset/images/product/ecopulse/adv7.svg";

import WhyEco from "../../asset/images/product/why_ecopulse.webp";
import ProductCTAA from '../../Component/ProductCTAA';
import ModalVideo from "react-modal-video";
import "react-modal-video/scss/modal-video.scss";
import EcopulseTable from './Ecopulse_components/EcopulseTable';
import GetInTouch from '../../Component/Get_in_touch';
import EcoPulseFaq from './Ecopulse_components/EcopulseFaq';

import ecopulse from "../../asset/images/new_banner/eco_pulse_banner.webp"

export default function Ecopulse() {
  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);


  const [isOpen, setIsOpen] = useState(false);

  const openModal = () => {
    setIsOpen(true);
  };



  return (
    <>


      <Helmet>
        <meta charset="UTF-8" />
        <title>EcoPulse - Macro-Economic Insights for Financial Intermediaries </title>
        <meta name="description" content="Elevate your trading strategies with EcoPulse. Get real-time macro-economic data for informed decisions. Contact us for the future of market content." />
        <meta name="author" content="Decimal Point Analytics" />
        <link rel="canonical" href="https://www.decimalpointanalytics.com/ecopulse" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org/",
              "@type": "Product",
              "name": "EcoPulse",
              "image": "https://www.decimalpointanalytics.com/static/media/ecopulsebanner.10a7a396c742d59ca936.webp",
              "description": "EcoPulse is a technology enabled platform created to deliver professional financial market content to financial intermediaries across currency, commodity and other markets",
              "brand": {
                "@type": "Brand",
                "name": "Decimal Point Analytics"
              }
            }
          `}
        </script>
      </Helmet>


      <Box id="product-page">

        {/* <Box id='ecopulse' className="mainsection" component="section">
        <Container maxWidth="lg">
          <Grid container spacing={4} alignItems="center">
            <Grid item lg={6} md={6} sm={6} xs={12}>
              <Box>
                <Typography variant="h1" className="blue">
                  EcoPulse
                </Typography>

                <Typography variant="h4" className="black">
                  Stay Ahead of the Curve with EcoPulse's  Comprehensive Macro-Economic Insights
                </Typography>
              </Box>


              <Box className='bt_button' mt={2}>
                <a target="_blank" rel="noopener noreferrer" href="
https://objectstorage.ap-mumbai-1.oraclecloud.com/n/bmexgnkisxsv/b/marketing_dpa/o/EcoPulse%2FLatest_Report%2FEcoPulse_Latest_Report.pdf">
                  <Button variant="contained" className='dpabtn_sm1'>
                    Download The Latest Report
                  </Button>
                </a>
              </Box>

            </Grid>
            <Grid item lg={6} md={6} sm={6} xs={12}>
              <Box className="creditvdo" style={{ backgroundImage: 'url(' + indexbanner + ')', }}>
                <IconButton className="pulse-button pulse_button_2" onClick={openModal} >
                </IconButton>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box> */}

        <Box className="banner_bx">
          <Box style={{ backgroundImage: "url(" + ecopulse + ")" }} className="banner_img">
            <Box className="img_over bl_overlay">
              <Grid container spacing={0} alignItems="center" justifyContent='center'>
                <Grid item lg={4} md={5} sm={7} xs={10}>
                  <Box className="al_center" mt={2}>
                    <Typography className="white" variant="h1">EcoPulse</Typography>
                    <Box mt={'10px'}>
                      <Typography className="white" variant="h4">Stay Ahead Of The Curve With EcoPulse’s Comprehensive Macro-Economic Insights</Typography>
                      <Box className='banner_btns'>
                        <Box className='bt_button'>
                          <a target="_blank" rel="noopener noreferrer" href="
https://objectstorage.ap-mumbai-1.oraclecloud.com/n/bmexgnkisxsv/b/marketing_dpa/o/EcoPulse%2FLatest_Report%2FEcoPulse_Latest_Report.pdf">
                            <Button variant="contained" className='dpabtn_sm1'>
                              Download The Latest Report
                            </Button>
                          </a>
                        </Box>
                        <Box className='banner_pulse'>
                          <IconButton className="header_pulse_button" onClick={openModal} >
                          </IconButton>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Box>


        <Box id='ecopulse' className='section2 sec_bg' component='section'>
          <Container maxWidth='lg' className='bdr'>

            {/* <Grid container spacing={1} alignItems="top">
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Box className='al_center'>
                  <Typography variant='h2' className='black'>
                    What is EcoPulse?
                  </Typography>
                </Box>
              </Grid>
            </Grid> */}


            <Grid container direction="row"
              justifyContent="center"
              alignItems="center">
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Box className="al_center synopsic_bx">
                  <Typography variant='h6' className='black jst'>
                    EcoPulse is a revolutionary technology-enabled platform that empowers financial intermediaries with comprehensive macro-economic insights. It serves as your go-to source for macro-economic data, treasury auctions and major central bank speeches across various countries. By providing timely and accurate information, EcoPulse equips you with the tools you need to make well-informed trading decisions.
                  </Typography>

                  <Typography variant='h6' className='black jst'>
                    At the core of EcoPulse is a sophisticated infrastructure that aggregates and delivers crucial macro-economic data to help you stay ahead of the curve. Our platform leverages innovative technologies to gather data from diverse sources and presents it to you in a user-friendly and intuitive format. With real-time updates on major macro-economic indicators, you can stay updated with the latest developments that impact your trading strategies.
                  </Typography>

                </Box>

                <Box mt={2}>
                  <ProductCTAA allignment="flx_fs" cta_content="Experience the Power of EcoPulse." cta_btn_name="Book Your Free Demo Now!" pageVisited="Eco Pulse" />
                </Box>

              </Grid>
            </Grid>
          </Container>
        </Box>



        <Box id='ecopulse' className="section" component="section">
          <Container maxWidth="lg" className="bdr">
            <Grid container spacing={1} alignItems="top">
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Box className="al_center">
                  <Typography className="black" variant="h2"> Harness the Advantages of EcoPulse:
                    <Typography variant="h2" className="black h2v2"> Unleashing Unparalleled Benefits for Your Business </Typography>
                  </Typography>
                </Box>
              </Grid>
            </Grid>

            <Box mt={3}>
              <Grid container spacing={2} alignItems="top">
                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <Box className="cardbx">
                    <Box mb={2}>
                      <img src={adv1} className="bxicon" alt="ECO1" />
                    </Box>

                    <Box>
                      <Box mr={1} ml={1}>
                        <Typography variant="h4" className="blue two">
                          Real-time Market Updates:
                        </Typography>
                      </Box>

                      <Box mr={1} ml={1}>
                        <Typography variant='h6' className='black al_center'>
                          Stay ahead with instant access to economic <strong> updates</strong>.
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Grid>

                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <Box className="cardbx">
                    <Box mb={2}>
                      <img src={adv2} className="bxicon" alt="ECO1" />
                    </Box>

                    <Box>
                      <Box mr={1} ml={1}>
                        <Typography variant="h4" className="blue two">
                          Extensive Coverage:
                        </Typography>
                      </Box>

                      <Box mr={1} ml={1}>
                        <Typography variant='h6' className='black al_center'>
                          Understand global <strong> market trends </strong> with over 43,000 recurring indicators from 136 countries/regions.
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Grid>

                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <Box className="cardbx">
                    <Box mb={2}>
                      <img src={adv3} className="bxicon" alt="ECO1" />
                    </Box>

                    <Box>
                      <Box mr={1} ml={1}>
                        <Typography variant="h4" className="blue two" >
                          Seamless Branding:
                        </Typography>
                      </Box>

                      <Box mr={1} ml={1}>
                        <Typography variant='h6' className='black al_center'>
                          Integrate EcoPulse into your website for consistent and professional brand representation.
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Grid>

                <Grid item lg={3} md={3} sm={6} xs={12}>
                  <Box className="cardbx">
                    <Box mb={2}>
                      <img src={adv4} className="bxicon" alt="ECO1" />
                    </Box>

                    <Box>
                      <Box mr={1} ml={1}>
                        <Typography variant="h4" className="blue two" >
                          Lightning-fast Information:
                        </Typography>
                      </Box>

                      <Box mr={1} ml={1}>
                        <Typography variant='h6' className='black al_center'>
                          Get real-time updates on critical market moving macro-economic data for timely trades.
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Grid>

                <Grid item lg={3} md={3} sm={6} xs={12}>
                  <Box className="cardbx">
                    <Box mb={2}>
                      <img src={adv5} className="bxicon" alt="ECO1" />
                    </Box>

                    <Box>
                      <Box mr={1} ml={1}>
                        <Typography variant="h4" className="blue two" >
                          Advanced Release Calendar:
                        </Typography>
                      </Box>

                      <Box mr={1} ml={1}>
                        <Typography variant='h6' className='black al_center'>
                          Stay ahead of market movements with a monitored release calendar.
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Grid>


                <Grid item lg={3} md={3} sm={6} xs={12}>
                  <Box className="cardbx">
                    <Box mb={2}>
                      <img src={adv6} className="bxicon" alt="ECO1" />
                    </Box>

                    <Box>
                      <Box mr={1} ml={1}>
                        <Typography variant="h4" className="blue two" >
                          Trusted Data Sources:
                        </Typography>
                      </Box>

                      <Box mr={1} ml={1}>
                        <Typography variant='h6' className='black al_center'>
                          Rely on data sourced from reliable institutions for authentic and accurate information.
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Grid>


                <Grid item lg={3} md={3} sm={6} xs={12}>
                  <Box className="cardbx">
                    <Box mb={2}>
                      <img src={adv7} className="bxicon" alt="ECO1" />
                    </Box>

                    <Box>
                      <Box mr={1} ml={1}>
                        <Typography variant="h4" className="blue two" >
                          Customization and Integration:
                        </Typography>
                      </Box>

                      <Box mr={1} ml={1}>
                        <Typography variant='h6' className='black al_center'>
                          Tailor EcoPulse to your needs and access historical data via APIs or SFTPs.
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Grid>

              </Grid>
            </Box>
          </Container>
        </Box>


        <Box id='ecopulse' className="section sec_bg" component="section">
          <Container maxWidth="lg" className="bdr">
            <Grid container spacing={0} alignItems="center" justifyContent='center'>
              <Grid item lg={8} md={10} sm={12} xs={12}>
                <Box className="al_center" pb={'2%'}>
                  <Typography className="black" variant="h2">
                    Why Choose Ecopulse ?
                  </Typography>
                </Box>

                <Box className="al_center">
                  <img src={WhyEco} className="topbanner2" alt="Banner2" />
                </Box>

                <Box>
                  <ProductCTAA allignment="al_center" cta_content="Discover EcoPulse:" cta_btn_name="Schedule Your Demo Today!" pageVisited="Eco Pulse" />
                </Box>


              </Grid>
            </Grid>
          </Container>
        </Box>





        <Box id='ecopulse' className="section" component="section">
          <Container maxWidth="lg" className="bdr">
            <Grid container spacing={1} alignItems="top">
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Box className="al_center">
                  <Typography className="black" variant="h2"> Break-up of Recurring Indicators by
                    <Typography variant="h2" className="black h2v2"> Country & Indicator Type </Typography>
                  </Typography>
                </Box>
              </Grid>
            </Grid>

            <Box mt={3}>
              <EcopulseTable />
            </Box>

            <Box mt={4}>
              <ProductCTAA allignment="flx_center" cta_content="Experience The Power EcoPulse" cta_btn_name="Book a free demo now!" pageVisited="Eco Pulse" />
            </Box>


          </Container>
        </Box>



        <Box id='ecopulse' className='section sec_bg' component='section'>
          <Container maxWidth='lg' className='bdr'>
            <EcoPulseFaq />
          </Container>
        </Box>








        <Box className="al_center">
          <GetInTouch />
        </Box>






        <Writemessage3 pageVisited="Eco Pulse" />

      </Box>

      <ModalVideo
        channel="youtube"
        isOpen={isOpen}
        videoId="YANI_bxmNB8"
        onClose={() => setIsOpen(false)}
      />



    </>
  );
}
