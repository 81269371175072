import { Container, Grid, Typography, Box, } from '@material-ui/core';
import React, { useEffect } from 'react';
import Aos from 'aos'
import "aos/dist/aos.css"
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import Writemessage3 from '../../Component/Writemessage3'
import bi1 from "../../asset/images/innovation/bi1.svg";
import bi2 from "../../asset/images/innovation/bi2.svg";
import bi3 from "../../asset/images/innovation/bi3.svg";
import bi4 from "../../asset/images/innovation/bi4.svg";
import bi5 from "../../asset/images/innovation/bi5.svg";
import banenr2 from "../../asset/images/animation/bimid.webp";

import { Helmet } from 'react-helmet';

import BusinessIntelligence from "../../asset/images/new_banner/bussiness_intelligence_banner.webp"

export default function Businessintelligence() {



    useEffect(() => {
        Aos.init({ duration: 1000 });
    }, []);


    return (
        <>


            <Helmet>
                <meta charset="UTF-8" />
                <h1>Make Informed Business Decision, Overcome The Analytical Bottleneck</h1>
                <title>Business Intelligence Solution In US- Decimal Point Analytics</title>
                <meta name="description" content="Turn raw data into meaningful insights which helps businesses to make better decisions with best business intelligence solution in US. Contact Us Now" />
                <meta name="keywords" content="business intelligence analytics
business intelligence consulting
enterprise business intelligence" />
                <meta name="author" content="Decimal Point Analytics" />
                <link rel="canonical" href="https://www.decimalpointanalytics.com/business-intelligence-solution" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            </Helmet>

            {/* <Box className='mainsection' component='section'>
                <Container maxWidth='lg'>
                    <Grid container spacing={1} alignItems="center">
                        <Grid item lg={5} md={6} sm={6} xs={12}>
                            <Box>
                                <Typography variant='h1' className='blue'>
                                    Business Intelligence
                                </Typography>

                                <Typography variant='h4' className='black'>
                                    Make Informed business decision, overcome the analytical bottleneck
                                </Typography>

                            </Box>
                        </Grid>
                        <Grid item lg={7} md={6} sm={6} xs={12}>
                            <Box className='al_right'>
                                <img src={indexbanner} className='topbanner' alt='Index Banner' />
                            </Box>
                        </Grid>
                    </Grid>
                </Container>
            </Box> */}

            <Box className="banner_bx">
                <Box style={{ backgroundImage: "url(" + BusinessIntelligence + ")" }} className="banner_img">
                    <Box className="img_over bl_overlay">
                        <Grid container spacing={0} alignItems="center" justifyContent='center'>
                            <Grid item lg={4} md={6} sm={8} xs={10}>
                                <Box className="al_center">
                                    <Typography className="white" variant="h1">Business Intelligence</Typography>
                                    <Box mt={'10px'}>
                                        <Typography className="white" variant="h4">Make Informed Business Decision, Overcome The Analytical Bottleneck</Typography>
                                    </Box>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </Box>

            <Box className='section2 sec_bg' component='section'>
                <Container maxWidth='lg' className='bdr'>

                    {/* <Grid container spacing={1} alignItems="top">
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                            <Box className='al_center'>

                                <Typography variant='h2' className='black'>
                                    Business Intelligence
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid> */}


                    <Grid container direction="row"
                        justifyContent="center"
                        alignItems="center">
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                            <Box className="al_center synopsic_bx">
                                <Typography variant='h6' className='black jst'>
                                    Business Intelligence helps you mine insight from the vast amount of data that you already own within the organization. Data is generated by different departments and held in several formats and this makes accessing and utilizing data a big challenge.
                                </Typography>
                                <Typography variant='h6' className='black jst'>
                                    For a bank which has a wide product range existing customers are a big target for up-selling. Most banks have some form of customer relationship management software which is used to service their clients. This CRM software is a gold mine of data which can give you signal about the customer behavior. But you need 360 degree view to make sense of the available opportunity and make strategic decisions.
                                </Typography>
                                <Typography variant='h6' className='black jst'>
                                    With the help of Business Intelligence you can combine this data with disparate raw data from various sources into meaningful insight.
                                </Typography>
                            </Box>

                        </Grid>
                    </Grid>
                </Container>
            </Box>
        
            <Writemessage3 pageVisited="Business Intelligence" />

            <Box className='section mob_card' component='section'>
                <Container maxWidth='lg' className='bdr'>
                    <Grid container spacing={3} alignItems="top">

                        <Grid item lg={3} md={3} sm={12} xs={12}>
                            <Grid container spacing={3} alignItems="center">
                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                    <Box className='boxbg'>
                                        <Box className='al_center'>
                                            <img src={bi1} className='allic' alt='BI1' />
                                        </Box>
                                        <Box>
                                            <Typography variant='h6' className='black al_center'>Generate Leads for Cross-Selling and Up-Selling</Typography>
                                        </Box>
                                    </Box>
                                </Grid>

                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                    <Box className='boxbg'>
                                        <Box className='al_center'>
                                            <img src={bi2} className='allic' alt='BI2' />
                                        </Box>
                                        <Box>
                                            <Typography variant='h6' className='black al_center'>Predict Probability of Customer Churn</Typography>
                                        </Box>
                                    </Box>
                                </Grid>

                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                    <Box className='boxbg'>
                                        <Box className='al_center'>
                                            <img src={bi3} className='allic' alt='BI3' />
                                        </Box>
                                        <Box>
                                            <Typography variant='h6' className='black al_center'>Performance Metrics and Benchmarking of Business Goals</Typography>
                                        </Box>
                                    </Box>
                                </Grid>


                            </Grid>

                        </Grid>

                        <Grid item lg={6} md={6} sm={12} xs={12}>
                            <Box className='al_center'>
                                <img src={banenr2} className='topbanner2' alt='Banner2' />
                            </Box>
                        </Grid>

                        <Grid item lg={3} md={3} sm={12} xs={12}>
                            <Grid container spacing={3} alignItems="center">
                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                    <Box className='boxbg'>
                                        <Box className='al_center'>
                                            <img src={bi4} className='allic' alt='BI4' />
                                        </Box>
                                        <Box>
                                            <Typography variant='h6' className='black al_center'> Early Fraud Detection and Risk Mitigation</Typography>
                                        </Box>
                                    </Box>
                                </Grid>

                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                    <Box className='boxbg'>
                                        <Box className='al_center'>
                                            <img src={bi5} className='allic' alt='BI5' />
                                        </Box>
                                        <Box>
                                            <Typography variant='h6' className='black al_center'>Anomaly detection of demographic data </Typography>
                                        </Box>
                                    </Box>
                                </Grid>




                            </Grid>
                        </Grid>

                    </Grid>
                </Container>
            </Box>


        </>
    );
}
