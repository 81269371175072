import { Container, Grid, Typography, Box, } from '@material-ui/core';
import React, { useEffect } from 'react';
import Aos from 'aos'
import "aos/dist/aos.css"
import { Link } from 'react-router-dom';

import Nov22 from "../../asset/images/media/event/22nov_event.webp";
import Nov18 from "../../asset/images/media/event/18nov_event2.webp";
import Oct10 from "../../asset/images/media/event/10oct_event.webp";
import Oct5 from "../../asset/images/media/event/5oct_event.webp";
import Sep1 from "../../asset/images/media/event/1sep_event.webp";
import Mar24 from "../../asset/images/media/event/24mar_event.webp";
import Nov21 from "../../asset/images/media/event/21nov_event.webp";
import Feb22 from "../../asset/images/media/event/22feb_event.webp";
import Nov3 from "../../asset/images/media/event/3nov_event.webp";
import Aug21 from "../../asset/images/media/event/21aug_event.webp";
import Jul28 from "../../asset/images/media/event/28jul_event.webp";
import Nov30 from "../../asset/images/media/event/30nov_event.webp";
import RoundTable from "../../asset/images/media/event/round_table.webp";
import PRMIA from "../../asset/images/media/event/prmia_event.webp";
import EvolutionOfESG from '../../asset/images/insight/esg/round_table/evolution_of_esg.webp';
import YinandYang from "../../asset/images/media/event/yin_&_yang.webp";
import InnovationSummit from "../../asset/images/media/event/innovation_summit.webp";
import GenAI from "../../asset/images/media/event/genai_for_enterprises.webp";
import tokenization from "../../asset/images/media/event/power_of_tokenization.webp";


import Event_banner from "../../asset/images/new_banner/event_banner.webp"
import { Helmet } from 'react-helmet';

export default function Event() {


    useEffect(() => {
        Aos.init({ duration: 1000 });
    }, []);





    return (
        <>

            <Helmet>
                <title>Upcoming Events | Decimal Point Analytics </title>
                <meta name="description" content="Explore Decimal Point Analytics' upcoming events, showcasing industry insights and innovative solutions." />
                <link rel="canonical" href="https://www.decimalpointanalytics.com/event" />
            </Helmet>

            {/* <Box className='mainsection' component='section'>
                <Container maxWidth='lg'>
                    <Grid container spacing={1} alignItems="center">
                        <Grid item lg={5} md={6} sm={6} xs={12}>
                            <Box>
                                <Typography variant='h1' className='blue'>
                                    Event
                                </Typography>

                                <Typography variant='h4' className='black'>
                                    Join us in our insightful events.
                                </Typography>

                            </Box>
                        </Grid>
                        <Grid item lg={7} md={6} sm={6} xs={12}>
                            <Box className='al_right'>
                                <img src={indexbanner} className='topbanner' alt='index banner' />
                            </Box>
                        </Grid>
                    </Grid>
                </Container>
            </Box> */}

            <Box className="banner_bx">
                <Box style={{ backgroundImage: "url(" + Event_banner + ")" }} className="banner_img">
                    <Box className="img_over bl_overlay">
                        <Grid container spacing={0} alignItems="center" justifyContent='center'>
                            <Grid item lg={4} md={5} sm={6} xs={8}>
                                <Box className="al_center">
                                    <Typography className="white" variant="h1">Event</Typography>
                                    <Box mt={'10px'}>
                                        <Typography className="white" variant="h4">Join Us In Our Insightful Events.</Typography>
                                    </Box>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </Box>

            <Box className='section2 sec_bg' component='section'>
                <Container maxWidth='lg' className='bdr'>


                    {/* <Grid container spacing={1} alignItems="top">
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                            <Box className='al_center' mt={5}>
                                <Typography variant='h2' className='black'>
                                    Latest Events
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid> */}

                    <Box className='reportinfo'>
                        <Grid container spacing={1} alignItems="top">

                            <Grid item lg={4} md={4} sm={6} xs={12}>
                                <Link to='/unveiling-the-transformative-power-of-tokenization-insights-from-the-cfa-india-fintech-conference'>
                                    <Box className='reportm_bg'>
                                        <Box className='reportimg_bg' style={{ backgroundImage: 'url(' + tokenization + ')', }}></Box>
                                        <Box className="reporttitle_box">
                                            <Typography className='reporttitle two '>
                                                Unveiling the Transformative Power of Tokenization: Insights from the 6th CFA India Fintech Conference
                                            </Typography>
                                        </Box>
                                        <Box className='line'></Box>
                                        <Typography variant='h4' className='black' > Mar, 2024 </Typography>
                                    </Box>
                                </Link>
                            </Grid>

                            <Grid item lg={4} md={4} sm={6} xs={12}>
                                <Link to='/executive-roundtable-exploring-generative-ai-for-enterprises'>
                                    <Box className='reportm_bg'>
                                        <Box className='reportimg_bg' style={{ backgroundImage: 'url(' + GenAI + ')', }}></Box>
                                        <Box className="reporttitle_box">
                                            <Typography className='reporttitle two '>
                                                Executive Roundtable: Exploring Generative AI for Enterprises
                                            </Typography>
                                        </Box>
                                        <Box className='line'></Box>
                                        <Typography variant='h4' className='black' > Dec, 2023 </Typography>
                                    </Box>
                                </Link>
                            </Grid>

                            <Grid item lg={4} md={4} sm={6} xs={12}>
                                <Link to='/innovation-summit-2023-at-sda-bocconi-asia-center'>
                                    <Box className='reportm_bg'>
                                        <Box className='reportimg_bg' style={{ backgroundImage: 'url(' + InnovationSummit + ')', }}></Box>
                                        <Box className="reporttitle_box">
                                            <Typography className='reporttitle two '>
                                                Innovation Summit 2023 at SDA Bocconi Asia Center
                                            </Typography>
                                        </Box>
                                        <Box className='line'></Box>
                                        <Typography variant='h4' className='black' > Oct, 2023 </Typography>
                                    </Box>
                                </Link>
                            </Grid>

                            <Grid item lg={4} md={4} sm={6} xs={12}>
                                <Link to='/yin-&-yang-of-web3-&-ai-to-transform-insurance-processes'>
                                    <Box className='reportm_bg'>
                                        <Box className='reportimg_bg' style={{ backgroundImage: 'url(' + YinandYang + ')', }}></Box>
                                        <Box className="reporttitle_box">
                                            <Typography className='reporttitle two '>
                                                Yin & Yang of Web3 & AI to Transform Insurance Processes
                                            </Typography>
                                        </Box>
                                        <Box className='line'></Box>
                                        <Typography variant='h4' className='black' > Oct, 2023 </Typography>
                                    </Box>
                                </Link>
                            </Grid>


                            <Grid item lg={4} md={4} sm={6} xs={12}>
                                <Link to='/executive-roundtable-the-evolution-of-esg-reporting-implications-for-fund-managers'>
                                    <Box className='reportm_bg'>
                                        <Box className='reportimg_bg' style={{ backgroundImage: 'url(' + EvolutionOfESG + ')', }}></Box>
                                        <Box className="reporttitle_box">
                                            <Typography className='reporttitle two '>
                                                Executive Roundtable- The Evolution of ESG Reporting Implications for Fund Managers
                                            </Typography>
                                        </Box>
                                        <Box className='line'></Box>
                                        <Typography variant='h4' className='black' > Jul, 2023 </Typography>
                                    </Box>
                                </Link>
                            </Grid>



                            <Grid item lg={4} md={4} sm={6} xs={12}>
                                <Link to='/quantifying-esg-risk'>
                                    <Box className='reportm_bg'>
                                        <Box className='reportimg_bg' style={{ backgroundImage: 'url(' + PRMIA + ')', }}></Box>
                                        <Box className="reporttitle_box">
                                            <Typography className='reporttitle two '>
                                                Quantifying ESG Risk
                                            </Typography>
                                        </Box>
                                        <Box className='line'></Box>
                                        <Typography variant='h4' className='black' > Jun, 2023 </Typography>
                                    </Box>
                                </Link>
                            </Grid>


                            <Grid item lg={4} md={4} sm={6} xs={12}>
                                <Link to='/executive-roundtable-on-portfolio-alignment-tools'>
                                    <Box className='reportm_bg'>
                                        <Box className='reportimg_bg' style={{ backgroundImage: 'url(' + RoundTable + ')', }}></Box>
                                        <Box className="reporttitle_box">
                                            <Typography className='reporttitle two '>
                                                Executive Roundtable on Portfolio Alignment Tools: Current Practices and Outlook
                                            </Typography>
                                        </Box>
                                        <Box className='line'></Box>
                                        <Typography variant='h4' className='black' > Mar, 2023 </Typography>
                                    </Box>
                                </Link>
                            </Grid>




                            <Grid item lg={4} md={4} sm={6} xs={12}>
                                <Link to='/convergence-of-ai-and-iot'>
                                    <Box className='reportm_bg'>
                                        <Box className='reportimg_bg' style={{ backgroundImage: 'url(' + Nov30 + ')', }}></Box>
                                        <Box className="reporttitle_box">
                                            <Typography className='reporttitle two '>
                                                Convergence of AI & IoT for a safer and equal society
                                            </Typography>
                                        </Box>
                                        <Box className='line'></Box>
                                        <Typography variant='h4' className='black' > Nov, 2022 </Typography>
                                    </Box>
                                </Link>
                            </Grid>

                            <Grid item lg={4} md={4} sm={6} xs={12}>
                                <Link to='/application-of-machine-learning-in-catastrophe-modeling'>
                                    <Box className='reportm_bg'>
                                        <Box className='reportimg_bg' style={{ backgroundImage: 'url(' + Nov22 + ')', }}></Box>
                                        <Box className="reporttitle_box">
                                            <Typography className='reporttitle two '>
                                                Application of Machine Learning in Catastrophe Modeling
                                            </Typography>
                                        </Box>
                                        <Box className='line'></Box>
                                        <Typography variant='h4' className='black' > Nov, 2022 </Typography>
                                    </Box>
                                </Link>
                            </Grid>


                            <Grid item lg={4} md={4} sm={6} xs={12}>
                                <Link to='/data-analytics-making-it-work'>
                                    <Box className='reportm_bg'>
                                        <Box className='reportimg_bg' style={{ backgroundImage: 'url(' + Nov18 + ')', }}></Box>
                                        <Box className="reporttitle_box">
                                            <Typography className='reporttitle two '>
                                                Data Analytics: Making it work in the real world
                                            </Typography>
                                        </Box>
                                        <Box className='line'></Box>
                                        <Typography variant='h4' className='black' > Nov, 2022 </Typography>
                                    </Box>
                                </Link>
                            </Grid>


                            <Grid item lg={4} md={4} sm={6} xs={12}>
                                <Link to='/round-table-by-dpa'>
                                    <Box className='reportm_bg'>
                                        <Box className='reportimg_bg' style={{ backgroundImage: 'url(' + Oct10 + ')', }}></Box>
                                        <Box className="reporttitle_box">
                                            <Typography className='reporttitle two '>
                                                Round Table by DPA - Is Net zero still a myth?
                                            </Typography>
                                        </Box>
                                        <Box className='line'></Box>
                                        <Typography variant='h4' className='black' > Oct, 2022 </Typography>
                                    </Box>
                                </Link>
                            </Grid>


                            <Grid item lg={4} md={4} sm={6} xs={12}>
                                <Link to='/techniche-2022'>
                                    <Box className='reportm_bg'>
                                        <Box className='reportimg_bg' style={{ backgroundImage: 'url(' + Oct5 + ')', }}></Box>
                                        <Box className="reporttitle_box">
                                            <Typography className='reporttitle two '>
                                                Decimal point analytics a sponsorer in “Techniche 2022”
                                            </Typography>
                                        </Box>
                                        <Box className='line'></Box>
                                        <Typography variant='h4' className='black' > Oct, 2022 </Typography>
                                    </Box>
                                </Link>
                            </Grid>


                            <Grid item lg={4} md={4} sm={6} xs={12}>
                                <Link to='/are-developed-markets-riskier'>
                                    <Box className='reportm_bg'>
                                        <Box className='reportimg_bg' style={{ backgroundImage: 'url(' + Sep1 + ')', }}></Box>
                                        <Box className="reporttitle_box">
                                            <Typography className='reporttitle two '>
                                                Are Developed Markets Riskier Than Developing Markets?
                                            </Typography>
                                        </Box>
                                        <Box className='line'></Box>
                                        <Typography variant='h4' className='black' > Sep, 2022 </Typography>
                                    </Box>
                                </Link>
                            </Grid>


                            <Grid item lg={4} md={4} sm={6} xs={12}>
                                <Link to='/indo-french-chamber-of-commerce'>
                                    <Box className='reportm_bg'>
                                        <Box className='reportimg_bg' style={{ backgroundImage: 'url(' + Mar24 + ')', }}></Box>
                                        <Box className="reporttitle_box">
                                            <Typography className='reporttitle two '>
                                                Indo-French Chamber of Commerce and Industry (IFCCI)
                                            </Typography>
                                        </Box>
                                        <Box className='line'></Box>
                                        <Typography variant='h4' className='black' > Mar, 2021 </Typography>
                                    </Box>
                                </Link>
                            </Grid>


                            <Grid item lg={4} md={4} sm={6} xs={12}>
                                <Link to='/chetana-institutes-of-management'>
                                    <Box className='reportm_bg'>
                                        <Box className='reportimg_bg' style={{ backgroundImage: 'url(' + Nov21 + ')', }}></Box>
                                        <Box className="reporttitle_box">
                                            <Typography className='reporttitle two '>
                                                Chetana’s Institutes of Management
                                            </Typography>
                                        </Box>
                                        <Box className='line'></Box>
                                        <Typography variant='h4' className='black' >Nov, 2019 </Typography>
                                    </Box>
                                </Link>
                            </Grid>


                            <Grid item lg={4} md={4} sm={6} xs={12}>
                                <Link to='/mipm-ies-mcrc-convention'>
                                    <Box className='reportm_bg'>
                                        <Box className='reportimg_bg' style={{ backgroundImage: 'url(' + Feb22 + ')', }}></Box>
                                        <Box className="reporttitle_box">
                                            <Typography className='reporttitle two '>
                                                NIPM - IES MCRC Convention
                                            </Typography>
                                        </Box>
                                        <Box className='line'></Box>
                                        <Typography variant='h4' className='black' >Feb, 2019 </Typography>
                                    </Box>
                                </Link>
                            </Grid>


                            <Grid item lg={4} md={4} sm={6} xs={12}>
                                <Link to='/weschool-mumbai'>
                                    <Box className='reportm_bg'>
                                        <Box className='reportimg_bg' style={{ backgroundImage: 'url(' + Nov3 + ')', }}></Box>
                                        <Box className="reporttitle_box">
                                            <Typography className='reporttitle two '>
                                                WeSchool Mumbai
                                            </Typography>
                                        </Box>
                                        <Box className='line'></Box>
                                        <Typography variant='h4' className='black' > Nov, 2018 </Typography>
                                    </Box>
                                </Link>
                            </Grid>





                            <Grid item lg={4} md={4} sm={6} xs={12}>
                                <Link to='/professional-risk-managers-international-association'>
                                    <Box className='reportm_bg'>
                                        <Box className='reportimg_bg' style={{ backgroundImage: 'url(' + Aug21 + ')', }}></Box>
                                        <Box className="reporttitle_box">
                                            <Typography className='reporttitle two '>
                                                Professional Risk Managers' International Association
                                            </Typography>
                                        </Box>
                                        <Box className='line'></Box>
                                        <Typography variant='h4' className='black' > Aug, 2018 </Typography>
                                    </Box>
                                </Link>
                            </Grid>


                            <Grid item lg={4} md={4} sm={6} xs={12}>
                                <Link to='/nl-dalmia-college'>
                                    <Box className='reportm_bg'>
                                        <Box className='reportimg_bg' style={{ backgroundImage: 'url(' + Jul28 + ')', }}></Box>
                                        <Box className="reporttitle_box">
                                            <Typography className='reporttitle two '>
                                                NL Dalmia College
                                            </Typography>
                                        </Box>
                                        <Box className='line'></Box>
                                        <Typography variant='h4' className='black' > Jul, 2018 </Typography>
                                    </Box>
                                </Link>
                            </Grid>








                        </Grid>
                    </Box>


                </Container>
            </Box>

        </>
    );
}
