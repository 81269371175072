import { IconButton, Container, Grid, Typography, Box, } from '@material-ui/core';
import React, { useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Aos from 'aos'
import "aos/dist/aos.css"
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import CloseIcon from '@material-ui/icons/Close';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import sd from "../../asset/images/team/shailesh_dhuri.webp";
import ps from "../../asset/images/team/paresh_sharma.webp";
import gg from "../../asset/images/team/gaurav_gupta.webp";
// import ssd from "../../asset/images/team/sidharth_dembi.webp";
// import md from "../../asset/images/team/m_damodaran.webp";
// import dn from "../../asset/images/team/doug_neilson.webp";

import team from "../../asset/images/new_banner/team_banner.webp"
import { Helmet } from 'react-helmet';

const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);








export default function Management() {



    const [open, setOpen] = React.useState(false);
    const handleClickOpen = () => { setOpen(true); };
    const handleClose = () => { setOpen(false); };

    const [open2, setOpen2] = React.useState(false);

    const handleClickOpen2 = () => {
        setOpen2(true);
    };
    const handleClose2 = () => {
        setOpen2(false);
    };

    const [open3, setOpen3] = React.useState(false);

    const handleClickOpen3 = () => {
        setOpen3(true);
    };
    const handleClose3 = () => {
        setOpen3(false);
    };

    // const [open4, setOpen4] = React.useState(false);


    // const handleClose4 = () => {
    //     setOpen4(false);
    // };

    // const [open5, setOpen5] = React.useState(false);


    // const handleClose5 = () => {
    //     setOpen5(false);
    // };

    // const [open6, setOpen6] = React.useState(false);

    // const handleClickOpen6 = () => {
    //     setOpen6(true);
    // };
    // const handleClose6 = () => {
    //     setOpen6(false);
    // };




    useEffect(() => {
        Aos.init({ duration: 1000 });
    }, []);





    return (
        <>

            <Helmet>
                <title>Decimal Point Analytics Management Team </title>
                <meta name="description" content="Discover our management team at Decimal Point Analytics, offering a unique blend of financial services domain expertise and advanced computational skills." />
                <link rel="canonical" href="https://www.decimalpointanalytics.com/management" />
            </Helmet>

            {/* <Box className='mainsection' component='section'>
                <Container maxWidth='lg'>
                    <Grid container spacing={1} alignItems="center">
                        <Grid item lg={5} md={6} sm={6} xs={12}>
                            <Box>
                                <Typography variant='h1' className='blue'>
                                    Our Team
                                </Typography>

                                <Typography variant='h4' className='black'>
                                    Unique combination of Financial Services Domain Expertise and Computational skills
                                </Typography>

                            </Box>
                        </Grid>
                        <Grid item lg={7} md={6} sm={6} xs={12}>
                            <Box className='al_right'>
                                <img src={indexbanner} className='topbanner' alt='Index Banner' />
                            </Box>
                        </Grid>
                    </Grid>
                </Container>
            </Box> */}

            <Box className="banner_bx">
                <Box style={{ backgroundImage: "url(" + team + ")" }} className="banner_img">
                    <Box className="img_over bl_overlay">
                        <Grid container spacing={0} alignItems="center" justifyContent='center'>
                            <Grid item lg={4} md={4} sm={5} xs={8}>
                                <Box className="al_center">
                                    <Typography className="white" variant="h1">Our Team</Typography>
                                    <Box mt={'10px'}>
                                        <Typography className="white" variant="h4">Unique Combination of Financial Services Domain Expertise and Computational skills</Typography>
                                    </Box>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </Box>

            <Box className='section sec_bg' component='section'>
                <Container maxWidth='lg' className='bdr'>

                    <Grid container spacing={1} alignItems="top">
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                            <Box className='al_center'>
                                <Typography variant='h2' className='black'>
                                    Executive Board
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>


                    <Grid container direction="row"
                        justifyContent="center"
                        alignItems="center">
                        <Grid item lg={4} md={4} sm={6} xs={12} className='dpateam'>
                            <Box onClick={handleClickOpen} className='tmlink'>
                                <Box mt={4}>
                                    <Box className='team' style={{ backgroundImage: 'url(' + sd + ')', }}></Box>
                                </Box>

                                <Box mt={3}>
                                    <Typography className='teaminfo blue al_center'>
                                        Mr. Shailesh Dhuri
                                    </Typography>
                                </Box>
                                <Box className='line' style={{ margin: '0 auto' }}></Box>
                                <Box mt={1}>
                                    <Typography variant='h6' className='black al_center'>
                                        Chief Executive Officer
                                    </Typography>
                                </Box>
                            </Box>
                        </Grid>


                        <Grid item lg={4} md={4} sm={6} xs={12} className='dpateam'>
                            <Box onClick={handleClickOpen2} className='tmlink'>
                                <Box mt={4}>
                                    <Box className='team' style={{ backgroundImage: 'url(' + ps + ')', }}></Box>
                                </Box>

                                <Box mt={3}>
                                    <Typography className='teaminfo blue al_center'>
                                        Mr. Paresh Sharma
                                    </Typography>
                                </Box>
                                <Box className='line' style={{ margin: '0 auto' }}></Box>
                                <Box mt={1}>
                                    <Typography variant='h6' className='black al_center'>
                                        Managing Partner
                                    </Typography>
                                </Box>
                            </Box>
                        </Grid>

                        <Grid item lg={4} md={4} sm={6} xs={12} className='dpateam'>
                            <Box onClick={handleClickOpen3} className='tmlink'>
                                <Box mt={4}>
                                    <Box className='team' style={{ backgroundImage: 'url(' + gg + ')', }}></Box>
                                </Box>

                                <Box mt={3}>
                                    <Typography className='teaminfo blue al_center'>
                                        Mr. Gaurav Gupta
                                    </Typography>
                                </Box>
                                <Box className='line' style={{ margin: '0 auto' }}></Box>
                                <Box mt={1}>
                                    <Typography variant='h6' className='black al_center'>
                                        Managing Partner
                                    </Typography>
                                </Box>
                            </Box>
                        </Grid>


                    </Grid>
                </Container>
            </Box>


            {/* <Box className='section' component='section'>
                <Container maxWidth='lg' className='bdr'>

                    <Grid container spacing={1} alignItems="top">
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                            <Box className='al_center'>
                                <Typography variant='h2' className='black'>
                                    Operating Management
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>


                    <Grid container direction="row"
                        justifyContent="center"
                        alignItems="center">
                        <Grid item lg={4} md={4} sm={6} xs={12} className='dpateam'>
                            <Box onClick={handleClickOpen4} className='tmlink'>
                                <Box mt={4}>
                                    <Box className='team' style={{ backgroundImage: 'url(' + ssd + ')', }}></Box>
                                </Box>

                                <Box mt={3}>
                                    <Typography className='teaminfo blue al_center'>
                                        Mr. Sidharth Dembi
                                    </Typography>
                                </Box>
                                <Box className='line' style={{ margin: '0 auto' }}></Box>
                                <Box mt={1}>
                                    <Typography variant='h6' className='black al_center'>
                                        Director
                                    </Typography>
                                </Box>
                            </Box>
                        </Grid>



                    </Grid>
                </Container>
            </Box> */}


            {/* <Box className='section' component='section'>
                <Container maxWidth='lg' className='bdr'>

                    <Grid container spacing={1} alignItems="top">
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                            <Box className='al_center'>
                                <Typography variant='h2' className='black'>
                                    Our Mentors
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>


                    <Grid container direction="row"
                        justifyContent="center"
                        alignItems="center">

                        <Grid item lg={4} md={4} sm={6} xs={12} className='dpateam'>
                            <Box onClick={handleClickOpen5} className='tmlink'>
                                <Box mt={4}>
                                    <Box className='team' style={{ backgroundImage: 'url(' + md + ')', }}></Box>
                                </Box>

                                <Box mt={3}>
                                    <Typography className='teaminfo blue al_center'>
                                        Mr. Meleveetil Damodaran
                                    </Typography>
                                </Box>
                                <Box className='line' style={{ margin: '0 auto' }}></Box>
                                <Box mt={1}>
                                    <Typography variant='h6' className='black al_center'>
                                        Trusted Advisor to the Indian Corporate and Financial Sector, Ex-Chairman of SEBI
                                    </Typography>
                                </Box>
                            </Box>
                        </Grid>


                        <Grid item lg={4} md={4} sm={6} xs={12} className='dpateam'>
                            <Box onClick={handleClickOpen6} className='tmlink'>
                                <Box mt={4}>
                                    <Box className='team' style={{ backgroundImage: 'url(' + dn + ')', }}></Box>
                                </Box>

                                <Box mt={3}>
                                    <Typography className='teaminfo blue al_center'>
                                        Doug Neilson
                                    </Typography>
                                </Box>
                                <Box className='line' style={{ margin: '0 auto' }}></Box>
                                <Box mt={1}>
                                    <Typography variant='h6' className='black al_center'>
                                        A global Banking,<br /> Credit and Risk Practitioner
                                    </Typography>
                                </Box>
                            </Box>
                        </Grid>


                    </Grid>
                </Container>
            </Box> */}






            <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open} className="b_modal teammodal" maxWidth="md">
                <DialogTitle className="feedname" id="customized-dialog-title" onClose={handleClose}>
                </DialogTitle>
                <DialogContent>
                    <Grid container spacing={2} alignItems="center">
                        <Grid item lg={4} md={4} sm={6} xs={12}>
                            <Box mt={4}>
                                <Box className='teama' style={{ backgroundImage: 'url(' + sd + ')', }}></Box>
                            </Box>
                        </Grid>
                        <Grid item lg={8} md={8} sm={6} xs={12}>
                            <Box mt={3}>
                                <Typography className='teaminfo blue al_left'>
                                    Mr. Shailesh Dhuri
                                </Typography>
                            </Box>
                            <Box className='line'></Box>
                            <Box mt={1}>
                                <Typography variant='h6' className='black al_left'>
                                    Cheif Executive Officer,
                                    <br />
                                    Decimal Point Analytics Pvt. Ltd.
                                </Typography>
                            </Box>
                            <Box style={{ marginTop: '-10px', marginLeft: '-4px' }}>
                                <a href='https://www.linkedin.com/in/shailesh-dhuri-68baa/' target='_blank' rel="noopener noreferrer">
                                    <IconButton aria-label="delete" size="small">
                                        <LinkedInIcon fontSize="small" color="primary" />
                                    </IconButton>
                                </a>
                            </Box>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} alignItems="center">
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                            <Box mt={3}>
                                <Typography variant='h6' className='black jst'>
                                    A result driven professional with nearly three decades of experience in Entrepreneurship, Fund Management and Artificial Intelligence for Data Analytics. He is a highly sought-after leader and Innovator in the space of real life applications of artificial intelligent technology that are used in massive data, quantitive, statistical analysis, and algorithms. His passion for innovation and technology driven solutions has been the backbone of Decimal Point Analytics since its inception in 2003.
                                </Typography>

                                <Typography variant='h6' className='black jst'>
                                    Shailesh has not only built strong and successful businesses of his own, but he has also supported many notable organizations in creating and sustaining a dynamic environment, even at the beginning of his career. From being instrumental in setting up Unit Trust India’s first Private Sector Bank to launching India’s first Money Market Fund back in 90’s, his vision for future has remained unparalleled to date.
                                </Typography>


                                <Typography variant='h6' className='black jst'>
                                    He is an alumnus of some of the most prestigious educational institutes in India and yet considers himself to be a forever student of mathematics and Philosophy. His strong academics include an MBA from IIM Bangalore, FRM from GARP, PRM from PRMIA, CFA from ICFAI, CWA from ICWAI and Bachelor of Commerce from Mumbai University are the key founding pillars of his career as an analyst and strategist of par excellence.
                                </Typography>

                                <Typography variant='h6' className='black jst'>
                                    He currently overlooks Growth Strategy, Financial Planning, Corporate Governance and Risk Analysis for Decimal Point Analytics, Algocircle and Advanced Investment Mechanics in the capacity of CEO and as an honorable board member. With his strong business acumen, he is focused on building new industry verticals by delivering excellent Business Strategy, Audit Plans and Risk Analysis for his current as well future ventures.
                                </Typography>


                            </Box>
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>

            <Dialog onClose={handleClose2} aria-labelledby="customized-dialog-title" open={open2} className="b_modal teammodal" maxWidth="md">
                <DialogTitle className="feedname" id="customized-dialog-title" onClose={handleClose2}>
                </DialogTitle>
                <DialogContent>
                    <Grid container spacing={2} alignItems="center">
                        <Grid item lg={4} md={4} sm={6} xs={12}>
                            <Box mt={4}>
                                <Box className='teama' style={{ backgroundImage: 'url(' + ps + ')', }}></Box>
                            </Box>
                        </Grid>
                        <Grid item lg={8} md={8} sm={6} xs={12}>
                            <Box mt={3}>
                                <Typography className='teaminfo blue al_left'>
                                    Mr. Paresh Sharma
                                </Typography>
                            </Box>
                            <Box className='line'></Box>
                            <Box mt={1}>
                                <Typography variant='h6' className='black al_left'>
                                    Managing Partner,
                                    <br />
                                    Decimal Point Analytics Pvt. Ltd.
                                </Typography>
                            </Box>
                            <Box style={{ marginTop: '-10px', marginLeft: '-4px' }}>
                                <a href='https://www.linkedin.com/in/paresh-sharma-b615ba5/' target='_blank' rel="noopener noreferrer">
                                    <IconButton aria-label="delete" size="small">
                                        <LinkedInIcon fontSize="small" color="primary" />
                                    </IconButton>
                                </a>
                            </Box>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} alignItems="center">
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                            <Box mt={3}>
                                <Typography variant='h6' className='black jst'>
                                    Paresh Sharma is the Managing Partner at Decimal Point Analytics and a driving force behind the company's Technology and Data Science. With years of experience under his belt, Paresh has a strong background in technology, equities, and research, having previously served as the Head of Equities and the Head of Research at a prominent fund house in India.
                                </Typography>

                                <Typography variant='h6' className='black jst'>
                                    A highly accomplished scholar, having completed his engineering from IIT-BHU and management from IIM-Bangalore. He is a passionate advocate of long-term investing and the transformative power of technology, always seeking new and innovative ways to combine the two.
                                </Typography>

                                <Typography variant='h6' className='black jst'>
                                    At Decimal Point Analytics, Paresh leads a team dedicated to providing clients with cutting-edge technology and solutions to help them navigate the ever-changing landscape of the financial industry. His visionary leadership and strategic vision have been instrumental in driving the company's growth and success.
                                </Typography>


                            </Box>
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>

            <Dialog onClose={handleClose3} aria-labelledby="customized-dialog-title" open={open3} className="b_modal teammodal" maxWidth="md">
                <DialogTitle className="feedname" id="customized-dialog-title" onClose={handleClose3}>
                </DialogTitle>
                <DialogContent>
                    <Grid container spacing={2} alignItems="center">
                        <Grid item lg={4} md={4} sm={6} xs={12}>
                            <Box mt={4}>
                                <Box className='teama' style={{ backgroundImage: 'url(' + gg + ')', }}></Box>
                            </Box>
                        </Grid>
                        <Grid item lg={8} md={8} sm={6} xs={12}>
                            <Box mt={3}>
                                <Typography className='teaminfo blue al_left'>
                                    Mr. Gaurav Gupta
                                </Typography>
                            </Box>
                            <Box className='line'></Box>
                            <Box mt={1}>
                                <Typography variant='h6' className='black al_left'>
                                    Managing Partner,
                                    <br />
                                    Decimal Point Analytics Pvt. Ltd.
                                </Typography>
                            </Box>
                            <Box style={{ marginTop: '-10px', marginLeft: '-4px' }}>
                                <a href='https://www.linkedin.com/in/gaurav-gupta-8a472a/' target='_blank' rel="noopener noreferrer">
                                    <IconButton aria-label="delete" size="small">
                                        <LinkedInIcon fontSize="small" color="primary" />
                                    </IconButton>
                                </a>
                            </Box>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} alignItems="center">
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                            <Box mt={3}>
                                <Typography variant='h6' className='black jst'>
                                    As one of the co-founders and the managing director at Decimal Point Analytics, Gaurav has been a key player in the growth and success of the company. A hands-on leader, he is closely involved in building the company's offerings and overseeing business development. Gaurav firmly believes in the power of creativity and innovation to transform financial markets, and he brings over two decades of experience as an active participant in global financial and capital markets to his role.
                                </Typography>


                                <Typography variant='h6' className='black jst'>
                                    Before founding Decimal Point Analytics, Gaurav managed assets for India's pioneering and largest asset manager. He leverages this extensive experience and deep expertise to help his team navigate the complexities of financial markets and deliver exceptional solutions to clients.
                                </Typography>


                                <Typography variant='h6' className='black jst'>
                                    One of Gaurav's greatest strengths is his ability to build meaningful relationships with financial services leaders. He relishes collaborating with these individuals and tackling their most pressing challenges using cutting-edge solutions provided by Decimal Point Analytics. Gaurav's passion for excellence and his unwavering commitment to his team and clients are essential drivers of the company's growth and success.

                                </Typography>

                            </Box>
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>

            {/* <Dialog onClose={handleClose4} aria-labelledby="customized-dialog-title" open={open4} className="b_modal teammodal" maxWidth="md">
                <DialogTitle className="feedname" id="customized-dialog-title" onClose={handleClose4}>
                </DialogTitle>
                <DialogContent>
                    <Grid container spacing={2} alignItems="center">
                        <Grid item lg={4} md={4} sm={6} xs={12}>
                            <Box mt={4}>
                                <Box className='teama' style={{ backgroundImage: 'url(' + ssd + ')', }}></Box>
                            </Box>
                        </Grid>
                        <Grid item lg={8} md={8} sm={6} xs={12}>
                            <Box mt={3}>
                                <Typography className='teaminfo blue al_left'>
                                    Mr. Sidharth Dembi
                                </Typography>
                            </Box>
                            <Box className='line'></Box>
                            <Box mt={1}>
                                <Typography variant='h6' className='black al_left'>
                                    Director,
                                    <br />
                                    Decimal Point Analytics Pvt. Ltd.
                                </Typography>
                            </Box>
                            <Box style={{ marginTop: '-10px', marginLeft: '-4px' }}>
                                <a href='https://www.linkedin.com/in/sidharth-dembi-46814318a/' target='_blank' rel="noopener noreferrer">
                                    <IconButton aria-label="delete" size="small">
                                        <LinkedInIcon fontSize="small" color="primary" />
                                    </IconButton>
                                </a>
                            </Box>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} alignItems="center">
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                            <Box mt={3}>
                                <Typography variant='h6' className='black jst'>
                                    Sidharth Dembi is a seasoned finance professional with over 25 years of experience in the industry. As the Director & Head of Finance at Decimal Point Analytics, he is responsible for overseeing the company's financial operations and ensuring its long-term financial sustainability.
                                </Typography>

                                <Typography variant='h6' className='black jst'>
                                    Before joining Decimal Point Analytics, Sidharth managed equity and balanced funds at a leading domestic fund house. In this role, he gained extensive knowledge of the financial markets and investment strategies, which he now leverages to drive the financial success of Decimal Point Analytics.
                                </Typography>

                                <Typography variant='h6' className='black jst'>
                                    Sidharth's expertise in the finance industry is not limited to investment management. Prior to his current role, he was responsible for delivering research solutions to the company's clients. His exceptional problem-solving and analytical skills, combined with his ability to communicate complex financial concepts in simple terms, made him an invaluable asset to the team.
                                </Typography>

                                <Typography variant='h6' className='black jst'>
                                    Sidharth holds a Bachelor's degree in in Engineering from NIT Surathkal and a Master's degree in Business Administration from Jamnalal Bajaj Institute of Management Studies.
                                </Typography>

                                <Typography variant='h6' className='black jst'>
                                    With his vast experience, expertise, and passion for finance, Sidharth is a key member of the Decimal Point Analytics Leadership team and plays a crucial role in the company's growth and success.
                                </Typography>



                            </Box>
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>

            <Dialog onClose={handleClose5} aria-labelledby="customized-dialog-title" open={open5} className="b_modal teammodal">
                <DialogTitle className="feedname" id="customized-dialog-title" onClose={handleClose5}>
                </DialogTitle>
                <DialogContent>
                    <Grid container spacing={2} alignItems="center">
                        <Grid item lg={4} md={4} sm={6} xs={12}>
                            <Box mt={4}>
                                <Box className='teama' style={{ backgroundImage: 'url(' + md + ')', }}></Box>
                            </Box>
                        </Grid>
                        <Grid item lg={8} md={8} sm={6} xs={12}>
                            <Box mt={3}>
                                <Typography className='teaminfo blue al_left'>
                                    Mr. Meleveetil Damodaran
                                </Typography>
                            </Box>
                            <Box className='line'></Box>
                            <Box mt={1}>
                                <Typography variant='h6' className='black al_left'>
                                    Trusted Advisor to the Indian Corporate and Financial Sector, Ex-Chairman of SEBI
                                </Typography>
                            </Box>

                        </Grid>
                    </Grid>
                    <Grid container spacing={2} alignItems="center">
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                            <Box mt={3}>
                                <Typography variant='h6' className='black jst'>
                                    Trusted advisor to the Government and Corporate sector Indian Administrative Services since 1971 Important positions Central Govt. of India Ex-Chairman of SEBI, Ex-Chairman of UTI Board Member of Large Indian corporates.
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>

            <Dialog onClose={handleClose6} aria-labelledby="customized-dialog-title" open={open6} className="b_modal teammodal">
                <DialogTitle className="feedname" id="customized-dialog-title" onClose={handleClose6}>
                </DialogTitle>
                <DialogContent>
                    <Grid container spacing={2} alignItems="center">
                        <Grid item lg={4} md={4} sm={6} xs={12}>
                            <Box mt={4}>
                                <Box className='teama' style={{ backgroundImage: 'url(' + dn + ')', }}></Box>
                            </Box>
                        </Grid>
                        <Grid item lg={8} md={8} sm={6} xs={12}>
                            <Box mt={3}>
                                <Typography className='teaminfo blue al_left'>
                                    Doug Neilson
                                </Typography>
                            </Box>
                            <Box className='line'></Box>
                            <Box mt={1}>
                                <Typography variant='h6' className='black al_left'>
                                    A global Banking, Credit and Risk Practitioner
                                </Typography>
                            </Box>
                            <Box>
                                <a href='https://www.linkedin.com/in/douglasneilson/' target='_blank' rel="noopener noreferrer">
                                    <IconButton aria-label="delete" size="small">
                                        <LinkedInIcon fontSize="small" color="primary" />
                                    </IconButton>
                                </a>
                            </Box>

                        </Grid>
                    </Grid>
                    <Grid container spacing={2} alignItems="center">
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                            <Box mt={3}>
                                <Typography variant='h6' className='black jst'>
                                    Leadership positions in global banks and Institutions Risk Management, Corporate Governance and Business Strategy Senior positions in RBS and Deutsche Bank globally, including CEO in India.
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog> */}




        </>
    );
}
