import {
  AppBar,
  Divider,
  Container,
  Grid,
  Tabs,
  Tab,
  Typography,
  Box,
  IconButton,
  Tooltip,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import ReactPlayer from "react-player";
import Aos from "aos";
import "aos/dist/aos.css";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import Carousel from "react-elastic-carousel";


import dd from "../../asset/images/team/durga_dande.webp";
import PN from "../../asset/images/team/prasad.webp";
import sp from "../../asset/images/team/shyam_pardeshi.webp";
import comma from "../../asset/images/aboutus/comma.svg";

import whocan from "../../asset/images/career/whocanapply.webp";
import pro from "../../asset/images/career/programmefeature.webp";
import culture from "../../asset/images/career/onboarding.webp";
import enjoy from "../../asset/images/career/enjoylearning.webp";
import trust from "../../asset/images/career/mutualtrust.webp";
import fun from "../../asset/images/career/funatwork.webp";
import boarding from "../../asset/images/career/onboarding.webp";
import kick from "../../asset/images/career/learninggrowth.webp";
import take from "../../asset/images/career/professionalgrowth.webp";
import degree from "../../asset/images/career/graduatedegree.webp";
import mentorship from "../../asset/images/career/mentorship.webp";
import career from "../../asset/images/career/careerpath.webp";
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';
import CareerForm from "../../Component/forms/CareerForm";
import { Helmet } from "react-helmet";


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`,
  };
}

export default function Careers() {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    window.scrollTo(0, 0);
    setValue(newValue);
  };




  const [currentVideoUrl, setCurrentVideoUrl] = useState('');

  const handleVideoPlay = (url) => {
    setCurrentVideoUrl(url);
  };




  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);




  return (
    <>


      <Helmet>
        <title>Careers Opportunities at Decimal Point Analytics </title>
        <meta name="description" content="Join Decimal Point Analytics for a rewarding career in finance. Explore opportunities with an innovative team dedicated to excellence." />
        <link rel="canonical" href="https://www.decimalpointanalytics.com/careers" />
      </Helmet>


      <Box className="maintabbedsection stiky" mb={5}>
        <Container maxWidth="lg" className="bdr">
          <Grid container spacing={1} alignItems="top">
            <Grid item lg={10} md={10} sm={12} xs={12}>
              <AppBar position="static" className="tabbedbar">
                <Tabs
                  value={value}
                  onChange={handleChange}
                  indicatorColor="none"
                  textColor="primary"
                  variant="scrollable"
                  scrollButtons="auto"
                  aria-label="scrollable auto tabs example"
                  centered
                >
                  <Tab label="Life as a Decimal" {...a11yProps(3)} />
                  <Tab label="Our Culture" {...a11yProps(2)} />
                  <Tab label="Journey of a Decimal" {...a11yProps(4)} />

                  <Tab label="ज्ञानोदपि धनोदपि" {...a11yProps(1)} className="fw6" />
                  <a
                    className="career"
                    href="https://dpa.darwinbox.in/ms/candidate/careers"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {/* <Tab label="Careers" {...a11yProps(0)} /> */}
                  </a>
                </Tabs>
              </AppBar>
            </Grid>
          </Grid>
        </Container>
      </Box>

      <TabPanel value={value} index={4} className="padd" pt={5}>
        <Box className="section2" component="section">
          <Container maxWidth="lg" className="bdr">
            <Grid container spacing={1} alignItems="top">
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Box className="al_center">
                  <Typography variant="h2" className="black">
                    Pending nokri.com
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Container>
        </Box>
      </TabPanel>

      <TabPanel value={value} index={3} pt={5}>
        <Container maxWidth="lg" className="bdr">
          <Box className="section2" component="section">
            {/* <Container maxWidth="lg" className="bdr"> */}
            <Grid container spacing={1} alignItems="top">
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Box className="al_left">
                  <Typography variant="h2" className="black">
                    Creating Opportunities, Transforming Lives
                  </Typography>
                </Box>
              </Grid>

              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Box>
                  <Typography variant="h6" className="black jst">
                    Earn while they learn with <strong>ज्ञानोदपि धनोदपि</strong>, enabling talented graduates to fulfil their needs while aiming for growth in the business ladder.
                  </Typography>

                  <Typography variant="h6" className="black jst">
                    Decimal Point Analytics recognizes the potential of brilliant and motivated graduates who want to
                    advance their education and develop successful professions. However, we recognize that financial
                    constraints and family obligations can frequently provide significant impediments to reaching these objectives. That is why we are pleased to present our Graduate Hiring Program, named as <strong>ज्ञानोदपि धनोदपि</strong>.
                  </Typography>
                </Box>
              </Grid>
            </Grid>
            {/* </Container> */}
          </Box>

          <Divider />

          <Box className="section2" component="section">
            {/* <Container maxWidth="lg" className="bdr"> */}
            <Grid container spacing={1} alignItems="top">
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Box className="al_left">
                  <Typography variant="h2" className="black">
                    Introducing our Program
                  </Typography>
                </Box>
              </Grid>

              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Box>
                  <Typography variant="h6" className="black jst">
                    <strong>ज्ञानोदपि धनोदपि</strong> is not just a program; it's a transformative journey designed to empower academically outstanding graduates from economically disadvantaged families. This programme combines corporate experience with post-graduate academic opportunities, giving students the skills, information, and support they need to succeed in today's competitive market.
                  </Typography>
                </Box>
              </Grid>
            </Grid>

            {/* </Container> */}
          </Box>


          <Divider />



          <Box className="section2" component="section">
            {/* <Container maxWidth="lg" className="bdr"> */}
            <Grid container spacing={1} alignItems="top">
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Box className="al_left">
                  <Typography variant="h2" className="black">
                    Details of the Program
                  </Typography>
                </Box>
              </Grid>



              <Grid item lg={8} md={8} sm={8} xs={12}>
                <Box className="al_center" mb={2}>
                  <Typography variant="h5" className="blue al_left">
                    Who can Apply
                  </Typography>
                </Box>
                <Box>
                  <Typography
                    variant="h6"
                    className="black al_left listdetails"
                  >
                    <ul>
                      <li>
                        <Typography variant='h6' className='black jst'>
                          Graduates who have qualification M.Sc. Economics/ M.A. Economics/ M.A. Mathematics/ M.Sc.
                          Statistics/Math/Physics/ B. Com/ M. Com/ B.Sc./ BBA/ BAF/ BMS (Accounting & Finance).
                        </Typography>
                      </li>
                      <li>
                        <Typography variant="h6" className="blue jst ">
                          Shortlist candidates based on following criteria:
                        </Typography>
                      </li>
                    </ul>
                  </Typography>

                  <ul type=''>
                    <li>
                      <Typography variant='h6' className='black jst'>
                        Average of 10th + 12th - <strong>70%</strong> and above.
                      </Typography>
                    </li>
                    <li>
                      <Typography variant='h6' className='black jst'>
                        Graduation -  <strong>60%</strong> and above.
                      </Typography>
                    </li>
                    <li>
                      <Typography variant='h6' className='black jst'>
                        Ration Card Type.
                      </Typography>
                    </li>
                    <li>
                      <Typography variant='h6' className='black jst'>
                        All students who have graduated <strong>post-2022</strong> are eligible to apply.
                      </Typography>
                    </li>
                    <li>
                      <Typography variant='h6' className='black jst'>
                        <strong>Application form</strong> will be online, and details shared later.
                      </Typography>
                    </li>
                    <li>
                      <Typography variant='h6' className='black jst'>
                        The <strong>selection of candidates</strong> will be basis tests and evaluations done by <strong>DPA</strong>. The results of the assessments will be shared with the institute and students <strong>post 15 days</strong>.
                      </Typography>
                    </li>
                  </ul>
                </Box>
              </Grid>
              <Grid item lg={4} md={4} sm={4} xs={12}>
                <Box className="al_center">
                  <img src={whocan} className="topbanner4" alt="Who Can" />
                </Box>
              </Grid>

            </Grid>
            {/* </Container> */}
          </Box>

          <Divider />

          <Box
            className="section"
            component="section"
            style={{ paddingTop: "5px" }}
          >
            {/* <Container maxWidth="lg" className="bdr"> */}


            <Box mt={1}>
              <Grid container spacing={1} alignItems="top">

                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <Box className="al_left" mt={2}>
                    <Typography variant="h2" className="black">
                      Why Choose ज्ञानोदपि धनोदपि ?
                    </Typography>
                  </Box>
                </Grid>


                <Grid item lg={8} md={8} sm={8} xs={12}>
                  <Box className="al_center" mb={2}>
                    <Typography variant="h5" className="blue al_left">
                      Earn a Post-graduate degree while working in a Corporate Environment.
                    </Typography>
                  </Box>
                  <Box>
                    <Typography
                      variant="h6"
                      className="black al_left listdetails"
                    >
                      <ul>
                        <li>
                          Gain practical experience in a corporate setting while furthering your education in your chosen subject.
                        </li>
                        <li>
                          Develop practical and theoretical skills to advance your career.
                        </li>
                      </ul>
                    </Typography>
                  </Box>
                </Grid>
                <Grid item lg={4} md={4} sm={4} xs={12}>
                  <Box className="al_center">
                    <img src={degree} className="topbanner4" alt="Who Can" />
                  </Box>
                </Grid>
              </Grid>
            </Box>
            <Divider />
            <Box mt={1}>
              <Grid container spacing={1} alignItems="top">
                <Grid item lg={8} md={8} sm={8} xs={12}>
                  <Box className="al_center" mb={2}>
                    <Typography variant="h5" className="blue al_left">
                      Mentorship and Training by Industry Experts.
                    </Typography>
                  </Box>
                  <Box>
                    <Typography
                      variant="h6"
                      className="black al_left listdetails"
                    >
                      <ul>
                        <li>
                          Receive personalised assistance and support from seasoned specialists at Decimal Point Analytics.
                        </li>
                        <li>
                          Join special training sessions and workshops to improve your abilities and broaden your knowledge.
                        </li>
                      </ul>
                    </Typography>
                  </Box>
                </Grid>
                <Grid item lg={4} md={4} sm={4} xs={12}>
                  <Box className="al_center">
                    <img src={mentorship} className="topbanner4" alt="Who Can" />
                  </Box>
                </Grid>
              </Grid>
            </Box>
            <Divider />
            <Box mt={1}>
              <Grid container spacing={1} alignItems="top">
                <Grid item lg={8} md={8} sm={8} xs={12}>
                  <Box className="al_center" mb={2}>
                    <Typography variant="h5" className="blue al_left">
                      Unconventional Career Path with Exciting Opportunities
                    </Typography>
                  </Box>
                  <Box>
                    <Typography
                      variant="h6"
                      className="black al_left listdetails"
                    >
                      <ul>
                        <li>
                          Create your own route to success by combining job experience and higher education.
                        </li>
                        <li>
                          Gain a competitive edge over peers by combining practical experience and academic proficiency.
                        </li>
                      </ul>
                    </Typography>
                  </Box>
                </Grid>
                <Grid item lg={4} md={4} sm={4} xs={12}>
                  <Box className="al_center">
                    <img src={career} className="topbanner4" alt="Who Can" />
                  </Box>
                </Grid>
              </Grid>
            </Box>
            <Divider />
            <Box mt={1}>
              <Grid container spacing={1} alignItems="top">
                <Grid item lg={8} md={8} sm={8} xs={12}>
                  <Box className="al_center" mb={2}>
                    <Typography variant="h5" className="blue al_left">
                      Program Features
                    </Typography>
                  </Box>
                  <Box>
                    <Typography
                      variant="h6"
                      className="black al_left listdetails"
                    >
                      <ul>
                        <li>
                          The candidates will undergo an Internship program for the first six months.
                        </li>
                        <li>
                          Post satisfactory completion of the internship, candidates would be taken on-rolls of DPA.
                        </li>
                        <li>
                          For the next 2 years each candidate will undergo a series of trainings, spanning across all
                          departments at DPA. These trainings will be coupled with job responsibilities for ease of
                          application and understanding.
                        </li>
                        <li>
                          Across the next two years, those who excel in work performance shall be sponsored for
                          postgraduation by DPA.
                        </li>
                        <li>
                          Employees on successful completion of post-graduation shall be eligible for higher roles in the organization.
                        </li>
                      </ul>
                    </Typography>
                    <Typography variant="h6" className="black jst">
                      Our goal is to establish a symbiosis between society and our organisation. In our country, we have many outstanding young brains with enormous potential who are hampered by financial restrictions or familial concerns. We wish to help these students develop their skill sets while also offering them a business placement with rewarding job option.
                    </Typography>
                  </Box>
                </Grid>
                <Grid item lg={4} md={4} sm={4} xs={12}>
                  <Box className="al_center">
                    <img src={pro} className="topbanner4" alt="Pro" />
                  </Box>
                </Grid>
              </Grid>
            </Box>
            <Divider />
            <Box className="al_center" mt={3}>
              <CareerForm />
              {/* <a
                href="https://docs.google.com/forms/d/1zzONIt17mYcr9ECJslCPhzukCiImqwpDMHGHoe__Fns/viewform?hl=en&pli=1&hl=en&edit_requested=true"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Button variant="contained" className="dpabtn_1">
                  Apply Now
                </Button>
              </a> */}
              <a href="https://objectstorage.ap-mumbai-1.oraclecloud.com/n/bmexgnkisxsv/b/marketing_dpa/o/%E0%A4%9C%E0%A5%8D%E0%A4%9E%E0%A4%BE%E0%A4%A8%E0%A5%8B%E0%A4%A6%E0%A4%AA%E0%A4%BF%20%E0%A4%A7%E0%A4%A8%E0%A5%8B%E0%A4%A6%E0%A4%AA%E0%A4%BF.pdf" download target="_blank" rel="noopener noreferrer">
                <Tooltip title="Download PDF" arrow placement="right">
                  <IconButton className="d-icobtn">
                    <DownloadForOfflineIcon fontSize="large" className="skblue" />
                  </IconButton>
                </Tooltip>
              </a>
            </Box>
            {/* </Container> */}
          </Box>


        </Container>
      </TabPanel>

      <TabPanel value={value} index={1} pt={5}>
        <Box className="section2" component="section">
          <Container maxWidth="lg" className="bdr">
            <Grid container spacing={1} alignItems="center">
              <Grid item lg={7} md={7} sm={6} xs={12}>
                <Box>
                  <Typography
                    variant="h6"
                    className="black jst listdetails"
                  >
                    At Decimal Point Analytics, we strongly believe that we are
                    on a mission, where we want to continuously challenge
                    ourselves everyday to try to outperform ourselves and be
                    better than what we were the previous day. While doing this,
                    we realize that we don’t get excited by being part of a
                    routine job or a career, but we thrive to be part of a much
                    bigger aspiration with a focus to achieve the ultimate goal
                    of self-actualization.
                  </Typography>

                  <Typography variant="h6" className="black jst">
                    We believe in continually raising the bar and exceed the
                    benchmarks that we set for ourselves in delivering solutions
                    to our clients. We derive satisfaction when we are able to
                    enhance the experience of our clients, through our ability
                    to use latest cutting-edge technologies by means of
                    Artificial Intelligence and Machine Learning tools. We are
                    passionate about improving our ways of doing things, be it
                    through automation or innovation. We embrace this culture
                    because we want to elevate ourselves into roles that do
                    justice to our capabilities, require us to think freely,
                    solve big problems and want us to innovate at every
                    opportunity. We are a part of a culture which allows us to
                    realize our true and complete potential.
                  </Typography>

                  <Typography variant="h6" className="black jst">
                    We want to be valuable contributors to society with a strong
                    system of ethics and values. We exist with an objective to
                    bring difference to the world and make it a better place to
                    be in. We do this by associating ourselves with institutions
                    and organizations that share similar mindset of working
                    towards upliftment and betterment of the society.
                  </Typography>

                  <Typography variant="h6" className="black jst">
                    If you are someone, who envisions to be on a journey of
                    continual self-improvement and simultaneously contribute
                    towards changing the world for the better, Decimal Point
                    Analytics is the right place for you to realize your
                    dreams!!
                  </Typography>
                </Box>
              </Grid>

              <Grid item lg={5} md={5} sm={6} xs={12}>
                <Box className="al_center">
                  <img src={culture} className="topbanner4" alt="Culture" />
                </Box>
              </Grid>
            </Grid>
          </Container>
        </Box>
      </TabPanel>

      <TabPanel value={value} index={0} pt={5}>
        <Box className="section2" component="section">
          <Container maxWidth="lg" className="bdr">
            <Box>
              <Grid container spacing={1} alignItems="top">
                <Grid item lg={8} md={8} sm={6} xs={12}>
                  <Box className="al_center" mb={2}>
                    <Typography variant="h5" className="blue al_left">
                      Enjoying while learning
                    </Typography>
                  </Box>
                  <Box>
                    <Typography
                      variant="h6"
                      className="black jst listdetails"
                    >
                      The life of a Decimal (Yes, Decimals what we fondly call our
                      people here!!) is an exciting journey where he/she is
                      continuously challenged and exposed to opportunities to grow
                      at a very fast pace. The opportunities are immense for
                      people who are thirsty for growth and who want to create a
                      difference to the world by their unique contributions to the
                      clients we serve and to the society in general. Our culture
                      is open and invigorating for minds who dream of achieving
                      the impossible.
                    </Typography>
                  </Box>
                </Grid>

                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <Box className="al_center">
                    <img src={enjoy} className="topbanner4" alt="Enjoy" />
                  </Box>
                </Grid>
              </Grid>
            </Box>
            <Divider />
            <Box mt={1}>
              <Grid container spacing={1} alignItems="top">
                <Grid item lg={8} md={8} sm={6} xs={12}>
                  <Box className="al_center" mb={2}>
                    <Typography variant="h5" className="blue al_left">
                      Mutual trust, care and respect
                    </Typography>
                  </Box>
                  <Box>
                    <Typography
                      variant="h6"
                      className="black jst listdetails"
                    >
                      We are happy that over these years we have been able to
                      foster positive and collaborative working relationships
                      which are based on care, trust and respect for each other.
                      You will feel the warmth and a sense of belongingness to
                      this open, friendly and transparent culture that exists
                      here.
                    </Typography>
                  </Box>
                </Grid>

                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <Box className="al_center">
                    <img src={trust} className="topbanner4" alt="Trust" />
                  </Box>
                </Grid>
              </Grid>
            </Box>
            <Divider />
            <Box mt={1}>
              <Grid container spacing={1} alignItems="top">
                <Grid item lg={8} md={8} sm={6} xs={12}>
                  <Box className="al_center" mb={2}>
                    <Typography variant="h5" className="blue al_left">
                      Fun at work
                    </Typography>
                  </Box>
                  <Box>
                    <Typography
                      variant="h6"
                      className="black jst listdetails"
                    >
                      We carry out the serious business of research with
                      ever-smiling-face and culture of performance, encouragement,
                      and innovation. We work hard and party even harder!! We do
                      not miss any festive occasion or any reason to celebrate,
                      making it a workspace filled with bonhomie. Picnics, team
                      outings, dinners, birthdays, games all add to the fun.
                      Most importantly, we celebrate and enjoy those together the
                      way a big happy family does.
                    </Typography>
                  </Box>
                </Grid>

                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <Box className="al_center">
                    <img src={fun} className="topbanner4" alt="Fun" />
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Container>
        </Box>

        <Box
          className="section"
          component="section"
          style={{ paddingTop: "0px" }}
        >
          <Container maxWidth="lg" className="bdr">
            <Grid container spacing={1} alignItems="top">
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Box className="al_center">
                  <Typography variant="h2" className="black">
                    Meet the Decimal
                  </Typography>
                </Box>
              </Grid>
            </Grid>
            <Grid container spacing={1} alignItems="top">
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Carousel
                  itemsToShow={1}
                  animateOut="slideOutDown"
                  animateIn="flipInX"
                >
                  <Box className="boxcontent">
                    <Box
                      className="team_testi"
                      style={{ backgroundImage: "url(" + dd + ")" }}
                    ></Box>
                    <Box className="test_text" mt={4}>
                      <Typography variant="h6" className="black al_center">
                        I have been associated with Decimal Point Analytics
                        (DPA) for the past 12 years and not for a single day did
                        I feel that I am not learning new things. It is a
                        pleasure to work for a company that is growing in the
                        right direction. On personal front, my organization has
                        allowed me to work flexi timings, which has helped me to
                        pursue a career which otherwise would not have been
                        possible. I feel proud to be a part of the DPA family.
                      </Typography>
                    </Box>
                    <Box
                      className="line"
                      style={{ margin: "0 auto" }}
                      mt={2}
                      mb={2}
                    ></Box>
                    <Box mt={4}>
                      <img src={comma} style={{ width: "50px" }} alt="Comma" />
                    </Box>
                    <Box mt={4}>
                      <Typography variant="h4" className="blue">
                        Durga Dande
                      </Typography>
                      <Typography variant="h6" className="blue">
                        Executive Vice President - Research and Account Management  | Mumbai
                      </Typography>
                    </Box>
                  </Box>

                  <Box className="boxcontent">
                    <Box
                      className="team_testi"
                      style={{ backgroundImage: "url(" + PN + ")" }}
                    ></Box>
                    <Box className="test_text" mt={4}>
                      <Typography variant="h6" className="black al_center">
                        Mentoring, trust, and freedom to innovate have been major aspects of my experience at DPA. The seniors and management have always mentored me at every step. At DPA, I was given a challenging opportunity early in my career to build a team of financial analysts at the Nashik location, along with a few other colleagues. I am proud to say that we have already established a robust team in Nashik, and it is growing rapidly, providing numerous career growth opportunities. DPA's work culture promotes a healthy work-life balance, allowing me to take time off when needed the most. Moreover, the organization has supported me during my medical emergency, and I truly feel that DPA is like a family to me. I look forward to many successful years of association with DPA.
                      </Typography>
                    </Box>
                    <Box
                      className="line"
                      style={{ margin: "0 auto" }}
                      mt={2}
                      mb={2}
                    ></Box>
                    <Box mt={4}>
                      <img src={comma} style={{ width: "50px" }} alt="Comma" />
                    </Box>
                    <Box mt={4}>
                      <Typography variant="h4" className="blue">
                        Prasad Nawathe
                      </Typography>
                      <Typography variant="h6" className="blue">
                        Senior Vice President - Research & Data Operations | Nashik
                      </Typography>
                    </Box>
                  </Box>

                  <Box className="boxcontent">
                    <Box
                      className="team_testi"
                      style={{ backgroundImage: "url(" + sp + ")" }}
                    ></Box>
                    <Box className="test_text" mt={4}>
                      <Typography variant="h6" className="black al_center">
                        It’s been a year working with Decimal point and the
                        journey has been full of learnings. The first thing that
                        pleasantly surprised me was the next gen analytics the
                        company provides to its clientele and the industry
                        expertise of the leadership team. The visionary
                        leadership has envisaged the futuristic technology and
                        has successfully implemented it as way of life in the
                        organisation. The organisation strongly believes in
                        learning & development and is instrumental in converting
                        talent from non-technical background into experts in
                        various technical domains. The procedures and policies
                        are employee friendly and nurtures a value based culture
                        which is critical for everyone’s success. I am delighted
                        to be a part of such a wonderful organisation and look
                        forward for a long tenure with full of learnings!
                      </Typography>
                    </Box>
                    <Box
                      className="line"
                      style={{ margin: "0 auto" }}
                      mt={2}
                      mb={2}
                    ></Box>
                    <Box mt={4}>
                      <img src={comma} style={{ width: "50px" }} alt="Comma" />
                    </Box>
                    <Box mt={4}>
                      <Typography variant="h4" className="blue">
                        Shyam Pardeshi
                      </Typography>
                      <Typography variant="h6" className="blue">
                        Senior Vice President - Research and Data Operations  | Nashik
                      </Typography>
                    </Box>
                  </Box>
                </Carousel>
              </Grid>
            </Grid>





            <Grid container spacing={3} alignItems="top">

              <Grid item lg={6} md={6} sm={12} xs={12}>
                <Box className="videback">
                  <ReactPlayer
                    controls
                    url="https://youtu.be/rOInKidIIJ8"
                    className="player"
                    width="100%"
                    height="100%"
                    playing={currentVideoUrl === 'https://youtu.be/rOInKidIIJ8'}
                    onPlay={() => handleVideoPlay('https://youtu.be/rOInKidIIJ8')}
                    onPause={() => setCurrentVideoUrl('')}
                  />
                </Box>
              </Grid>

              <Grid item lg={6} md={6} sm={12} xs={12}>
                <Box className="videback">
                  <ReactPlayer
                    controls
                    url="https://youtu.be/AHfdGfE5Mgg"
                    className="player"
                    width="100%"
                    height="100%"
                    playing={currentVideoUrl === 'https://youtu.be/AHfdGfE5Mgg'}
                    onPlay={() => handleVideoPlay('https://youtu.be/AHfdGfE5Mgg')}
                    onPause={() => setCurrentVideoUrl('')}
                  />
                </Box>
              </Grid>
            </Grid>




          </Container>
        </Box>
      </TabPanel>

      <TabPanel value={value} index={2} pt={5}>
        <Box className="section2" component="section">
          <Container maxWidth="lg" className="bdr">
            <Box mb={5}>
              <Grid container spacing={1} alignItems="top">
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <Box className="al_center">
                    <Typography variant="h2" className="black">
                      The Journey a Decimal Undertakes
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </Box>

            <Box>
              <Grid container spacing={1} alignItems="top">
                <Grid item lg={8} md={8} sm={6} xs={12}>
                  <Box className="al_center" mb={2}>
                    <Typography variant="h5" className="blue al_left">
                      On-boarding
                    </Typography>
                  </Box>
                  <Box>
                    <Typography
                      variant="h6"
                      className="black jst listdetails"
                    >
                      The first step into Decimal Point Analytics, you know that
                      you are at the right place where professional growth has no
                      boundaries for those with desire and passion to continually
                      learn, contribute and grow.
                    </Typography>

                    <Typography
                      variant="h6"
                      className="black jst listdetails"
                    >
                      While recruiting from campuses, we empathize with the
                      typical uncertainties that prevail in the minds of
                      newcomers, as the transition from the ‘University’ to the
                      ‘Corporate’ is always a challenging one. Therefore, one of
                      our key emphasis is to make your transition a very
                      comfortable and enjoyable experience. We do this by
                      providing you with a very friendly, transparent and open
                      culture consisting of induction programs, orientation &
                      acclimatization and buddy programs for bonding right at the
                      beginning of your professional journey with us. We ensure
                      that people who join us after gaining some industry
                      experience are equally thrilled by the exposure they get
                      while working on projects and assignments which not only
                      utilize their previous experience but add new dimensions to
                      their overall professional progression and growth.
                    </Typography>

                    <Typography
                      variant="h6"
                      className="black jst listdetails"
                    >
                      Our flat organization structure is meant to ensure that the
                      communication chain isn’t too long and you find people at
                      all levels easily accessible and approachable.
                    </Typography>
                  </Box>
                </Grid>

                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <Box className="al_center">
                    <img src={boarding} className="topbanner4" alt="Boarding" />
                  </Box>
                </Grid>
              </Grid>
            </Box>
            <Divider />
            <Box mt={1}>
              <Grid container spacing={1} alignItems="top">
                <Grid item lg={8} md={8} sm={6} xs={12}>
                  <Box className="al_center" mb={2}>
                    <Typography variant="h5" className="blue al_left">
                      Kick-starting your journey of learning and growth:
                    </Typography>
                  </Box>
                  <Box>
                    <Typography
                      variant="h6"
                      className="black jst listdetails"
                    >
                      Our clearly defined career paths, Fast Track career Program,
                      frequent feedback and review sessions, various learning and
                      development interventions and ongoing coaching and mentoring
                      programs are all designed to collaborate with you and drive
                      you towards achieving your professional objectives and
                      aspirations. Here, you will also witness and participate in
                      various discussion platforms, town-hall meetings, suggestion
                      boxes and many more such initiatives, which are a part of an
                      environment that strongly promotes creativity, innovation
                      and initiative among Decimals. Our employee engagement
                      programs are designed to ensure balanced growth. While, you
                      would love being a part of the family of Decimals, we want
                      you to have a proper work-life balance in a way that you
                      never fail to meet your personal commitments and priorities
                      on time. In fact, employee satisfaction is one of the key
                      contributors to Decimal Point Analytics’ consistent
                      excellence.
                    </Typography>
                  </Box>
                </Grid>

                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <Box className="al_center">
                    <img src={kick} className="topbanner4" alt="kick" />
                  </Box>
                </Grid>
              </Grid>
            </Box>
            <Divider />
            <Box mt={1}>
              <Grid container spacing={1} alignItems="top">
                <Grid item lg={8} md={8} sm={6} xs={12}>
                  <Box className="al_center" mb={2}>
                    <Typography variant="h5" className="blue al_left">
                      Taking cruise-control of your professional growth
                    </Typography>
                  </Box>
                  <Box>
                    <Typography
                      variant="h6"
                      className="black jst listdetails"
                    >
                      Our work culture is defined by our Mantra “Generate Alpha,
                      Reduce Risk”. We always strive to ‘achieve the ultimate’ for
                      our clients as well as employees within this framework. This
                      gives opportunity to Decimals – to ‘go for the ultimate’,
                      creating value for all stakeholders in the process and to
                      fast track the professional growth.
                    </Typography>
                  </Box>
                </Grid>

                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <Box className="al_center">
                    <img src={take} className="topbanner4" alt="Take" />
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Container>
        </Box>
      </TabPanel>
    </>
  );
}
