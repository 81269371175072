import { Container, Typography, Box, Grid } from '@material-ui/core';
import React, { useEffect } from 'react';
import Aos from 'aos'
import "aos/dist/aos.css"
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';


import Writemessage3 from '../../../Component/Writemessage3';

import Blogback from '../../../Component/Blogback'
import { Helmet } from 'react-helmet';
import EmployeeProfile from '../../../Component/EmployeeProfile';
import SD from '../../../asset/images/team/shailesh_dhuri.webp'


export default function Bank_runs_in_balance_sheet() {

    useEffect(() => {
        Aos.init({ duration: 1000 });
    }, []);


    return (
        <>

            <Helmet>
                <meta charset="UTF-8" />
                <h1>How to Value “Bank Runs” in Balance Sheet? </h1>
                <title>How to Value “Bank Runs” in Balance Sheet?</title>
                <meta name="description" content="Learn how to value bank runs in balance sheets and prevent failures. Explore solutions to enhance transparency and stability with Decimal Point Analytics." />
                <meta name="keywords" content="bank runs, balance sheet valuation, liquidity option, deposits repayable on call, HTM, AFS, financial results, value estimation, interest rate risk, put option, complexity in banking, transparency in banking." />
                <meta name="author" content="Decimal Point Analytics" />
                <link rel="canonical" href="https://www.decimalpointanalytics.com/blog/bank-runs-in-balancesheet" />

                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            </Helmet>



            <Box className='section sec_bg' component='section' id='bank_runs'>
                <Container maxWidth='lg' className='bdr'>

                    <Grid container spacing={1} alignItems="top" justifyContent='center'>
                        <Grid item lg={10} md={10} sm={12} xs={12}>
                            <Typography variant='h3' className='subhead skblue al_center'>
                                Blog
                            </Typography>
                            <Typography variant='h1' className='blue mainhead al_center'>
                                How to Value “Bank Runs” in Balance Sheet?
                            </Typography>
                        </Grid>
                    </Grid>


                    <Box className='whitebx'>

                        <Typography variant='h6' className='black jst' gutterBottom>Silicon Valley Bank failed a few days back. Two simple explanations for the failure:
                            <ol>
                                <li>  The deposits were repayable on call, but bank invested nearly everything in long dated bond.  </li>
                                <li> Auditors looked the other way when management refused to account for losses on long dated bond by using respectable looking props like “Hold to Maturity” HTM and “Available for Sale” AFS. These are childishly dangerous concepts which should not exist, but regulators created them so that banks can manipulate profits to their desired levels.   </li>
                            </ol>
                        </Typography>


                        <Typography variant='h6' className='black jst' gutterBottom>  What are the solutions to avoid this in future? Two solutions. One simple and one complex.
                            <ol>
                                <li> Kill the concepts of HTM and AFS. Simple enough. Everyone will have two less things to account for when studying balance sheets of banks. However, it will not fly. Because then there is less scope for banks to produce financial results that they want to show.  </li>
                                <li> So, here comes the complex solution. Value the liquidity option embedded in deposits repayable on call. This will create more employment for consultants, and financial analysts. Banks can continue to show what they want to show on asset side and still investors can better guess the value of bank, as they have one more variable to counter the two unnecessary variables.    </li>
                            </ol>
                        </Typography>


                        <Typography variant='h6' className='black jst' gutterBottom>  So, how to value liquidity option in deposits repayable on call.  Three logical steps:
                            <ol>
                                <li> Depositors want their money back either for transactions or for fear/greed factor.  </li>
                                <li> Ignore the transaction motivation because, on average, over medium term, the liquidity demand for transactions will cancel out for a reasonable size bank.   </li>
                                <li> Liquidity demand for fear/greed factor is nothing but price risk. Specifically interest rate risk. Assume deposits have life of 5 years. So they are a 5 year bond, which the deposit can sell back to bank at par at any time. Valuing this option is easy.  </li>
                            </ol>
                        </Typography>





                        <Typography variant='h6' className='black jst'>  Now that deposits are constructed as a “short position on a long-dated bond + short position on put option on the same bond”, bank should value the short put option. After that bank should account for that value in balance sheet.  </Typography>

                        <Typography variant='h6' className='black jst'>  Banks will now be wary of accepting demand deposits, because they will no more  be low-cost deposits. The short position on the put option will be priced regularly and the real cost of deposits will be known to investors.   </Typography>



                        <Typography variant='h6' className='black jst'>  So, more complexity to account for complexity created by HTM/AFS. But that should be ok. Who says world needs to go to simplicity.  </Typography>




                        <Box>
                            <Box mt={1}>
                                <EmployeeProfile imgSrc={SD} name={"Mr. Shailesh Dhuri"} designation={"Chief Executive Officer"} company={"Decimal Point Analytics Pvt. Ltd."} linkedinUrl={"https://www.linkedin.com/in/shaileshdhuri/"} />
                            </Box>
                        </Box>



                        {/* <Box mt={5}>
                            <Subscribe />
                        </Box> */}



                    </Box>


                    <Box className='pages'>
                        <Writemessage3 pageVisited="How to Value “Bank Runs” in Balance Sheet?" />
                    </Box>

                    <Blogback />


                </Container>
            </Box>

        </>
    );
}
