import { Container, Grid, Typography, Box, } from '@material-ui/core';
import React, { useEffect } from 'react';
import Aos from 'aos'
import "aos/dist/aos.css"
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';


import Writemessage3 from '../../../Component/Writemessage3';
import Casestudyback from '../../../Component/Casestudyback'

import Analytical_dash1 from "../../../asset/images/insight/case_studies/analytical_dashboard1.webp";
import Analytical_dash2 from "../../../asset/images/insight/case_studies/analytical_dashboard2.webp";



export default function Analytical_dashboard_solution() {

    useEffect(() => {
        Aos.init({ duration: 1000 });
    }, []);


    return (
        <>

            <Helmet>
                <meta charset="UTF-8" />
                <h1>Automated & Dynamic Analytical Dashboards Solution For An Indian PE Firm</h1>
                <title>Automated & Dynamic Analytical Dashboards Solution For An Indian PE Firm | Decimal Point Analytics</title>
                <meta name="description" content="Case study of Indian Equity Firm with AUM's worth $2bn teamed up with best artificial intelligence data analytics company to solve their complex data problem. Read Now!" />
                <meta name="keywords" content="
AI in data analytics,
artificial intelligence solutions company,
market research solutions,
Sector research provider" />
                <meta name="author" content="Decimal Point Analytics" />
                <link rel="canonical" href="https://www.decimalpointanalytics.com/case-studies/utomated-and-dynamic-analytical-dashboards-solution-for-an-indian-pe-firm" />

                <meta name="viewport" content="width=device-width, initial-scale=1.0" />

            </Helmet>



            <Box id="case-studies" className='section sec_bg' component='section'>

                <Container maxWidth='lg' className='bdr'>

                    <Grid container spacing={3} alignItems="top" justifyContent='center'>
                        <Grid item lg={10} md={10} sm={12} xs={12}>
                            <Box>
                                <Typography variant='h3' className='subhead skblue al_center'>
                                    Success Stories
                                </Typography>
                                <Typography className='mainhead blue al_center mb0'>
                                    Automated & dynamic analytical dashboards solution for an Indian PE firm
                                </Typography>

                                <Typography className='mainsmhead black al_center '>
                                    An Indian equity firm with AUMs worth $2bn and 23 company portfolios across three funds teamed up with Decimal Point Analytics to perform automated and meaningful analysis of unstructured and complex data.
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>


                    <Writemessage3 pageVisited="Automated & dynamic analytical dashboards solution for an Indian PE firm" />



                    <Box className='whitebx'>
                        <Box>
                            {/* <Typography variant='h4' className='blue' >Client Overview</Typography> */}
                            <Typography variant='h6' className='black jst'>
                                Data and historical statistics have become organizations' heart for efficient functioning of a business. Today, disruption and innovation lie in data. To be differentiated, organizations are investing in this asset intelligently. Transforming data into actionable insights can build long-term and sustainable business strategies. Previously the analysis was carried out manually on excel sheets. It resulted in slow information flow, occupying multiple man-hours with low accuracy. It needed a simple process by incorporating calculations into an ETL Flow and displaying data in the BI dashboard. Decimal Point Analytics offered them a harmonized solution with a better decision-making scope. It also aided in keeping the business running more proficiently at a reduced cost.
                            </Typography>
                        </Box>
                    </Box>


                    <Box className='whitebx' mt={1}  >
                        <Grid container spacing={3} alignItems="top">
                            <Grid item lg={4} md={4} sm={4} xs={12}>
                                <Box className='al_center'>
                                    <Box className='casestudy_bg' style={{ backgroundImage: 'url(' + Analytical_dash1 + ')', }}></Box>
                                </Box>
                            </Grid>
                            <Grid item lg={8} md={8} sm={8} xs={12}>
                                <Box>



                                    <Typography variant='h4' className='blue' >The Challenges </Typography>

                                    <Typography variant='h6' className='black jst'>
                                        The data given to us was unformatted and present in multiple input sheets. Also, the time lag during authentication from the client’s end resulted in delayed access to their systems.
                                    </Typography>

                                    <Typography variant='h6' className='black jst'>
                                        The significant obstacles listed by the client were:
                                        <ul>
                                            <li> Client had to toggle across approx. 30 excel sheets and update the formulas to generate output. </li>
                                            <li> Highly prone to errors due to manual processing. </li>
                                            <li> If client want to check and compare output with previous quarters, they had to open multiple excel files. </li>

                                        </ul>
                                    </Typography>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>





                    <Box className='whitebx' mt={1}  >
                        <Grid container spacing={3} alignItems="center">
                            <Grid item lg={4} md={4} sm={4} xs={12}>
                                <Box className='al_center'>
                                    <Box className='casestudy_bg' style={{ backgroundImage: 'url(' + Analytical_dash2 + ')', }}></Box>
                                </Box>
                            </Grid>
                            <Grid item lg={8} md={8} sm={8} xs={12}>
                                <Box>

                                    {/* <Box mb={2}>
                                        <Link to='/analystpulse'>
                                            <Typography variant='h4' className='blue' >Know More About Our Product on Analytics – AnalystPulse </Typography>
                                        </Link>
                                    </Box> */}

                                    <Typography variant='h4' className='blue' > Our Approach </Typography>


                                    <Typography variant='h6' className='black jst'>
                                        The organization needed automated data synchronization and analysis through dashboards to speed up decision-making. We assisted them by:
                                        <ul>
                                            <li>Creating standard templates which will capture all raw data (transactions, FX rate, market value, expenses, etc) in one excel itself.</li>
                                            <li> Created database design for storing all the above information which is used for further analysis. </li>
                                            <li> Created ETL flows to load the raw data into database.</li>
                                            <li>We also incorporated logic in ETL to categorize portfolio companies into realized, unrealized and partially realized.</li>
                                            <li>ETL also performed calculation of NAV.</li>
                                            <li>Designed interactive BI dashboards which is connected to database to display the desired output.</li>
                                            <li>Some of the calculations like Gross & Net IRR, Net MOC, DPI, TPVI were performed by BI tool.</li>
                                        </ul>
                                    </Typography>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>





                    <Box className='whitebx' mt={1}>
                        <Box>
                            <Typography variant='h4' className='blue' >
                                The Result</Typography>
                            <Typography variant='h6' className='black jst'>
                                The automated solution helped client in reducing the turnaround time for data collection and analysis drastically. Thus, our client quickly turned the dashboard information into key business decisions when needed. The ongoing benefits of these solutions are:
                                <ul>
                                    <li>Streamlined and error-free data  </li>
                                    <li>Significant cost and time saving</li>
                                    <li>Introduced the client to BI dashboards</li>
                                    <li>In-depth insights with slice & dice functions through dashboards</li>
                                    <li>Ability to compare historical data at one place</li>
                                </ul>
                            </Typography>
                        </Box>
                    </Box>



                    <Box className='whitebx' mt={1}>
                        <Box>
                            <Typography variant='h4' className='blue' >Enable better decisions with Decimal Point Analytics</Typography>
                            <Typography variant='h6' className='black jst'>
                                In the age of digital transformation, data automation and machine learning are the keys to tomorrow's business. The advent of technology has altered how businesses gather, store, and analyze information. Organizations all across the world are now acknowledging the significance of gaining insights from unstructured data and making data-driven choices. This means that if you want to win over your competition and keep abreast of your business, getting meaningful insights from data is no longer an option, it is a necessity.
                            </Typography>
                            <Typography variant='h6' className='black jst'>
                                We streamlined and automated all the sources for our clients so that they could get the required inputs with just a few clicks. Thus, helping them expand the market and implement cost-efficient routes to scale up processing and analyze crucial data.
                            </Typography>
                            <Typography variant='h6' className='black jst'>
                                If you would also want your complex data in a simplified structure that utilizes an enhanced data-driven decision-making procedure, then we would love to have a word with you.
                            </Typography>


                            <Link to='/contact-us'>
                                <Typography variant='h6' className='blue jst'>
                                    Call Now !
                                </Typography>
                            </Link>

                        </Box>
                    </Box>



                    <Box className='graybbx' mt={1} >
                        <Grid container spacing={1} alignItems="top" >
                            <Grid item lg={12} md={12} sm={12} xs={12} >
                                <Box className='al_left'>
                                    <Typography variant='h4' className='black'>
                                        Explore More:
                                    </Typography>

                                    <Link to='/case-studies/fraud-detection-and-analytics-for-a-leading-german-reinsurer'>
                                        <Typography variant='h6' className='black jst' style={{ marginBottom: '0px' }}>
                                            <b>Next Case Study:</b> Fraud Detection & Analytics for a Leading German Reinsurer.

                                        </Typography>
                                    </Link>

                                    <Link to='/articles/challenges-in-operationalizing-esg-norms-in-asset-management'>
                                        <Typography variant='h6' className='black jst'>
                                            <b>Insights: </b>Challenges in operationalizing ESG norms in Asset Management.
                                        </Typography>
                                    </Link>

                                </Box>
                            </Grid>
                        </Grid>



                    </Box>


                    <Casestudyback />




                </Container>
            </Box>


        </>
    );
}
