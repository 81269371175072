import { Container, Typography, Box, Grid } from '@material-ui/core';
import React, { useEffect } from 'react';
import Aos from 'aos'
import "aos/dist/aos.css"
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import Insightback from '../../../Component/Insightback'

import Writemessage3 from '../../../Component/Writemessage3';
import { Helmet } from 'react-helmet';
import EmployeeProfile from '../../../Component/EmployeeProfile';
import SD from '../../../asset/images/team/shailesh_dhuri.webp'
import Requiem from "../../../asset/images/insight/insight_pages/article.webp"


export default function ESG_requiem() {

    useEffect(() => {
        Aos.init({ duration: 1000 });
    }, []);

    return (
        <>

            <Helmet>
                <meta charset="UTF-8" />
                <title>Requiem for ESG? Debunking Critics & Embracing Sustainability </title>
                <meta name="description" content="Discover the ongoing debate on the future of ESG investing and its long-term benefits, despite recent criticisms." />
                <meta name="keywords" content="ESG, Sustainable Investing, Financial Opinion, Aswath Damodaran, ESG Performance, Investment Strategy, Climate Change, Paris Agreement, Risk Management, Corporate Responsibility, Social Goals, UNPRI, Finance Debate, Long-Term Investing, Regulatory Changes, Financial Markets, Asset Management, Environmental Governance, Social Responsibility, Governance Principles." />
                <meta name="author" content="Decimal Point Analytics" />
                <link rel="canonical" href="https://www.decimalpointanalytics.com/articles/is-it-time-to-write-esg-requiem-not-quite" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            </Helmet>


            <Box id="article-page" className='section sec_bg' component='section'>

                <Container maxWidth='lg' className='bdr'>

                    <Grid container spacing={1} alignItems="top" justifyContent='center' >
                        <Grid item lg={10} md={12} sm={12} xs={12}>
                            <Box>
                                <Typography variant='h3' className='subhead skblue al_center'>
                                    Insight
                                </Typography>
                                <Typography variant='h1' className='blue mainhead al_center' style={{ textTransform: 'none' }}>
                                    Is it time to write ESG’s Requiem? Not quite
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>




                    <Box className='whitebx'>



                        <Box pt={1} pb={1}>
                            {/* <Typography variant='h4' className='blue' gutterBottom> MRR – the way forward:  </Typography> */}

                            <Typography variant='h6' className='black jst' gutterBottom><em>There is a lot to criticize in the world of ESG as it exists today. An FT piece called for an end to ESG. However, the underlying principles have merit and a place that will live, even if the form evolves. The critics are guilty of the same crime as the accused – missing the signal in the noise.
                            </em></Typography>

                            <Typography variant='h6' className='black jst' gutterBottom>
                                Earlier in the week, the Financial Times (FT) carried an opinion piece by noted academic Aswath Damodaran titled ‘ESG Is Beyond Redemption: May It RIP’.
                            </Typography>


                            <Typography variant='h6' className='black jst' gutterBottom>
                                To be sure, this was coming. In the last couple of years, ESG has faced increasing backlash, as investors and regulators voice concern over the state of affairs. However, some of those concerns are very different from what Aswath says. While the former have asked for regulation and reform, Aswath calls for an outright end to ESG.
                            </Typography>

                            <Typography variant='h6' className='black jst' gutterBottom>
                                Aswath’s article lands some heavy blows. He suggests ESG fails investors, then he extends the argument to say that it fails the real economy companies trying to apply ESG principles.
                            </Typography>

                            <Box pt={1} pb={1}>
                                <Grid container spacing={1} alignItems="top" >
                                    <Grid item lg={9} md={9} sm={9} xs={12}>
                                        <Box>
                                            <Typography variant='h6' className='black jst' gutterBottom> His criticism for ESG’s unsuitability for investors builds on the Ukraine war issue. The war led to fossil stocks and arms companies outperforming, leading to ESG investors underperforming after several years of outperformance.
                                            </Typography>
                                            <Typography variant='h6' className='black jst' gutterBottom>Moving to corporates, he writes higher ESG score can increase value smaller and serving niche markets, while decreasing value at other companies where it adds to costs while doing nothing for revenues.
                                            </Typography>
                                            <Typography variant='h6' className='black jst' gutterBottom>He further adds: advocates who argue that improving ESG will make firms less risky are directly contradicting other claims that investors will earn higher returns if they invest in high ESG companies.
                                            </Typography>
                                        </Box>
                                    </Grid>

                                    <Grid item lg={3} md={3} sm={3} xs={12}>
                                        <Box className='al_center'>
                                            {/* <Box className='casestudy_bg' style={{ backgroundImage: 'url(' + IMG1 + ')', }}></Box> */}
                                            <img src={Requiem} className='topbanner2 requiem_img' alt="ESG's Requiem" />
                                            <Typography variant='h6' className='black js_center ' gutterBottom>
                                                "Off with ESG’s head!"
                                            </Typography>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Box>

                            <Typography variant='h6' className='black jst' gutterBottom>
                                Coming from a highly respected academic, these arguments are stunningly facile.
                            </Typography>

                            <Typography variant='h4' className='blue jst fw6' gutterBottom>
                                Let us point out some fallacies:
                            </Typography>

                            <Typography variant='h6' className='black jst fallacies'>
                                <ul>
                                    <li> <strong> Ukraine war and ESG funds underperforming: </strong> In factor investing, it is well accepted that a factor strategy may not always outperform. For example, in a runaway bull market, value will underperform. In other words, mere underperformance is no reason to discredit a factor. Is ESG an independent factor – that is a separate debate. Aswath has not gone into it, so we will also avoid that tricky issue here. Opinion remains divided here. One can certainly question UN PRI’s guidance of absolute avoidance of investment in fossil fuels. Every sector needs a glidepath to net zero; fossil fuel companies are also making attempts to transform their business. To club them with adult content or gambling and completely exclude them from portfolios reflects a folly of the ESG lobby. </li>

                                    <li> <strong>ESG focus adds no value to companies:  </strong>Companies embracing ESG are likely to underperform, goes this line of argument. We argue this is short-termism. Is Aswath trying to suggest that companies should stop focusing on Net Zero? If so, he is denying climate change. He is also dissing The Paris Agreement, which is a legally binding agreement agreed to over 190  countries. ESG has several social goals as well, like diversity and inclusion, fair pay, equal opportunity. As companies adapt to these, there may be some short-term costs, but over the long term, there should be gains. Is Aswath advocating a return to cowboy capitalism?</li>

                                    <li> <strong>	ESG lowers risk, so means lower returns: </strong> There is classic theoretical thinking. The key is the sequence of events. Consider this: A fund buys a stock; the company works on improving ESG metrics – for example, better governance, better supplier relations, reduced energy consumption, better product governance – in other words, business risk reduces; the market recognizes this, bids the stock up. Will an investor underperform or outperform in such a scenario? Risk is not static; management action can change it. In fact, long only investing is mostly a bet on the management’s ability to outperform in risk management. The purpose of ESG ‘engagements’ – investors discussing improvements with portfolio companies – is to reduce risk. This will not lower returns but will increase it.</li>

                                </ul>
                            </Typography>

                            <Typography variant='h6' className='black jst' gutterBottom>
                                In short, most of the key arguments Aswath makes in his article seem off the mark. ESG also has G pillar, which he ignores. Agency problem is a well-defined problem and G addresses that between owners and managers.
                            </Typography>


                            <Typography variant='h6' className='black jst' gutterBottom>
                                This is not to say the ESG market does not need change. ESG sellers have created a mess that needs to be cleaned up, and regulators in many jurisdictions are working on it. However, there is no case to call for the end of ESG. This is like asking for an end to social media, just because there is often content on it that some may find objectionable.
                            </Typography>

                        </Box>

                        <Box mt={2}>
                            <Box mt={1}>
                                <EmployeeProfile imgSrc={SD} name={"Mr. Shailesh Dhuri"} designation={"Chief Executive Officer"} company={"Decimal Point Analytics Pvt. Ltd."} linkedinUrl={"https://www.linkedin.com/in/shaileshdhuri/"} />
                            </Box>
                        </Box>


                    </Box>


                    <Box className='pages'>
                        <Writemessage3 pageVisited="Is it time to write ESG’s Requiem? Not quite" />
                    </Box>

                    <Insightback />


                </Container>
            </Box>


        </>
    );
}
