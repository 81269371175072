import { Container, Grid, Typography, Box } from "@material-ui/core";
import React, { useEffect } from "react";
import Aos from "aos";
import "aos/dist/aos.css";
import End_to_End from "../../asset/images/landing_page/services/end-to-end.webp"
import data_analytics from "../../asset/images/landing_page/services/data_analytics.webp"
import ai_ml from "../../asset/images/landing_page/services/ai_ml.webp"
import fund_management from "../../asset/images/landing_page/services/fund_management.webp"
import esg_solutions from "../../asset/images/landing_page/services/esg_solutions.webp"
import research_services from "../../asset/images/landing_page/services/research_services.webp"
import crm from "../../asset/images/landing_page/services/crm.webp"
import investor from "../../asset/images/landing_page/services/investor_relations.webp"

import ProductCTAA from '../../Component/ProductCTAA'



export default function HomeServices() {
  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);



  return (
    <>

      <Box id="services" className="section" component="section">
        <Container maxWidth="lg" className="bdr">

          <Box>
            <Grid container spacing={1} alignItems="top" justifyContent="center">
              <Grid item lg={9} md={10} sm={12} xs={12} >
                <Box className="al_center">
                  <Typography variant="h2" className="black mob_left">
                    Our Comprehensive Suite of Services
                  </Typography>
                  <Typography variant="h6" className="black mob_jst">
                    At DPA, we specialize in bespoke projects, ensuring every solution—from research to CRM—is perfectly aligned with your distinct business needs, enabling us to adapt to any project and customize it for optimal results.
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Box>

          <Box mt={3} className="desk_view_only">
            <Grid container spacing={2} alignItems="top" justifyContent="center">
              <Grid item lg={3} md={3} sm={6} xs={12}>
                <Box className="al_center services-card">
                  <Box className='img_box'>
                    <img src={End_to_End} className="service_img" alt="End-to-End Data Management" />
                  </Box>
                  <Box mt={2}>
                    <Typography className="blue fw5" variant="h6">
                      End-to-End 
                      <br/>
                      Data Management
                    </Typography>
                  </Box>
                  <Box className="flx_center" mt={1}>
                    <Box className="line"></Box>
                  </Box>
                </Box>
              </Grid>

              <Grid item lg={3} md={3} sm={6} xs={12}>
                <Box className="al_center services-card">
                  <Box className='img_box'>
                    <img src={data_analytics} className="service_img" alt="Data Analytics & Business Intelligence" />
                  </Box>
                  <Box mt={2}>
                    <Typography className="blue fw5" variant="h6">
                      Data Analytics & 
                      <br/>
                      Business Intelligence
                    </Typography>
                  </Box>
                  <Box className="flx_center" mt={1}>
                    <Box className="line"></Box>
                  </Box>
                </Box>
              </Grid>

              <Grid item lg={3} md={3} sm={6} xs={12}>
                <Box className="al_center services-card">
                  <Box className='img_box'>
                    <img src={ai_ml} className="service_img" alt="AI-ML and Automation Offerings" />
                  </Box>
                  <Box mt={2}>
                    <Typography className="blue fw5" variant="h6">
                      AI-ML and Automation 
                      <br/>
                      Offerings
                    </Typography>
                  </Box>
                  <Box className="flx_center" mt={1}>
                    <Box className="line"></Box>
                  </Box>
                </Box>
              </Grid>

              <Grid item lg={3} md={3} sm={6} xs={12}>
                <Box className="al_center services-card">
                  <Box className='img_box'>
                    <img src={fund_management} className="service_img" alt="Fund Management & PE Support Solutions" />
                  </Box>
                  <Box mt={2}>
                    <Typography className="blue fw5" variant="h6">
                      Fund Management & 
                      <br/>
                      PE Support Solutions
                    </Typography>
                  </Box>
                  <Box className="flx_center" mt={1}>
                    <Box className="line"></Box>
                  </Box>
                </Box>
              </Grid>

              <Grid item lg={3} md={3} sm={6} xs={12}>
                <Box className="al_center services-card">
                  <Box className='img_box'>
                    <img src={esg_solutions} className="service_img" alt="ESG Solutions" />
                  </Box>
                  <Box mt={2}>
                    <Typography className="blue fw5" variant="h6">
                      ESG Solutions
                    </Typography>
                  </Box>
                  <Box className="flx_center" mt={1}>
                    <Box className="line"></Box>
                  </Box>
                </Box>
              </Grid>

              <Grid item lg={3} md={3} sm={6} xs={12}>
                <Box className="al_center services-card">
                  <Box className='img_box'>
                    <img src={research_services} className="service_img" alt="Research Services" />
                  </Box>
                  <Box mt={2}>
                    <Typography className="blue fw5" variant="h6">
                      Research Services
                    </Typography>
                  </Box>
                  <Box className="flx_center" mt={1}>
                    <Box className="line"></Box>
                  </Box>
                </Box>
              </Grid>

              <Grid item lg={3} md={3} sm={6} xs={12}>
                <Box className="al_center services-card">
                  <Box className='img_box'>
                    <img src={crm} className="service_img" alt="CRM Support" />
                  </Box>
                  <Box mt={2}>
                    <Typography className="blue fw5" variant="h6">
                      CRM Support
                    </Typography>
                  </Box>
                  <Box className="flx_center" mt={1}>
                    <Box className="line"></Box>
                  </Box>
                </Box>
              </Grid>

              <Grid item lg={3} md={3} sm={6} xs={12}>
                <Box className="al_center services-card">
                  <Box className='img_box'>
                    <img src={investor} className="service_img" alt="Investor Relations" />
                  </Box>
                  <Box mt={2}>
                    <Typography className="blue fw5" variant="h6">
                      Investor Relations
                    </Typography>
                  </Box>
                  <Box className="flx_center" mt={1}>
                    <Box className="line"></Box>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Box>


          <Box mt={2} className="mob_view_only">
            <Grid container spacing={2} alignItems="top" justifyContent="center">
              <Grid item lg={3} md={3} sm={6} xs={12}>
                <Box className="al_center services-card">
                  <Box className='img_box'>
                    <img src={End_to_End} className="service_img" alt="End-to-End Data Management" />
                  </Box>
                  <Box mt={2}>
                    <Typography className="blue fw5" variant="h6">
                      End-to-End Data Management
                    </Typography>
                  </Box>
                  <Box className="flx_center" mt={1}>
                    <Box className="line"></Box>
                  </Box>
                </Box>
              </Grid>

              <Grid item lg={3} md={3} sm={6} xs={12}>
                <Box className="al_center services-card">
                  <Box className='img_box'>
                    <img src={data_analytics} className="service_img" alt="Data Analytics & Business Intelligence" />
                  </Box>
                  <Box mt={2}>
                    <Typography className="blue fw5" variant="h6">
                      Data Analytics & Business Intelligence
                    </Typography>
                  </Box>
                  <Box className="flx_center" mt={1}>
                    <Box className="line"></Box>
                  </Box>
                </Box>
              </Grid>

              <Grid item lg={3} md={3} sm={6} xs={12}>
                <Box className="al_center services-card">
                  <Box className='img_box'>
                    <img src={ai_ml} className="service_img" alt="AI-ML and Automation Offerings" />
                  </Box>
                  <Box mt={2}>
                    <Typography className="blue fw5" variant="h6">
                      AI-ML and Automation Offerings
                    </Typography>
                  </Box>
                  <Box className="flx_center" mt={1}>
                    <Box className="line"></Box>
                  </Box>
                </Box>
              </Grid>

              <Grid item lg={3} md={3} sm={6} xs={12}>
                <Box className="al_center services-card">
                  <Box className='img_box'>
                    <img src={fund_management} className="service_img" alt="Fund Management & PE Support Solutions" />
                  </Box>
                  <Box mt={2}>
                    <Typography className="blue fw5" variant="h6">
                      Fund Management & PE Support Solutions
                    </Typography>
                  </Box>
                  <Box className="flx_center" mt={1}>
                    <Box className="line"></Box>
                  </Box>
                </Box>
              </Grid>

              <Grid item lg={3} md={3} sm={6} xs={12}>
                <Box className="al_center services-card">
                  <Box className='img_box'>
                    <img src={esg_solutions} className="service_img" alt="ESG Solutions" />
                  </Box>
                  <Box mt={2}>
                    <Typography className="blue fw5" variant="h6">
                      ESG Solutions
                    </Typography>
                  </Box>
                  <Box className="flx_center" mt={1}>
                    <Box className="line"></Box>
                  </Box>
                </Box>
              </Grid>

              <Grid item lg={3} md={3} sm={6} xs={12}>
                <Box className="al_center services-card">
                  <Box className='img_box'>
                    <img src={research_services} className="service_img" alt="Research Services" />
                  </Box>
                  <Box mt={2}>
                    <Typography className="blue fw5" variant="h6">
                      Research Services
                    </Typography>
                  </Box>
                  <Box className="flx_center" mt={1}>
                    <Box className="line"></Box>
                  </Box>
                </Box>
              </Grid>

              <Grid item lg={3} md={3} sm={6} xs={12}>
                <Box className="al_center services-card">
                  <Box className='img_box'>
                    <img src={crm} className="service_img" alt="CRM Support" />
                  </Box>
                  <Box mt={2}>
                    <Typography className="blue fw5" variant="h6">
                      CRM Support
                    </Typography>
                  </Box>
                  <Box className="flx_center" mt={1}>
                    <Box className="line"></Box>
                  </Box>
                </Box>
              </Grid>

              <Grid item lg={3} md={3} sm={6} xs={12}>
                <Box className="al_center services-card">
                  <Box className='img_box'>
                    <img src={investor} className="service_img" alt="Investor Relations" />
                  </Box>
                  <Box mt={2}>
                    <Typography className="blue fw5" variant="h6">
                      Investor Relations
                    </Typography>
                  </Box>
                  <Box className="flx_center" mt={1}>
                    <Box className="line"></Box>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Box>

          <Box mt={5}>
            <ProductCTAA allignment="al_center" cta_btn_name="Schedule a free Demo today!" pageVisited="Artificial Intelligence" />
          </Box>

        </Container>
      </Box>

    </>
  );
}
